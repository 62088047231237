import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@helper/Tools';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@material-ui/core';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

export default function Payment() {
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    let history = useHistory();
    const styles = useStyles();
    const theme = useTheme();

    let { oid } = useParams();
    const query = useQuery();
    const source = query.get('source');
    const [dialog, setDialog] = useState({
        open : false,
        content : "",
        successPayment: false,
    });

    useEffect(() => {

        isMountedRef.current = true;
        if(oid) {
            getUrl(`validate/payment/${oid}/${source}`)
            .then(response => {
                if(isMountedRef.current) {
                    if(response.status && response.order_payment_status === true){
                        if(source === 'wallet'){ // system wallet
                            setDialog(dialog => ({ ...dialog, open: true, successPayment: true, title: t('payment.success'), content: t('payment.successContent') }));
                        } else {
                            if(response.payment_status === 10) {
                                setDialog(dialog => ({ ...dialog, open: true, successPayment: true, title: t('payment.success'), content: t('payment.successContent') }));
                            } else if(response.payment_status === 30) {
                                setDialog(dialog => ({ ...dialog, open: true, successPayment: true, title: t('payment.pending'), content: t('payment.pendingContent') }));
                            } else {
                                setDialog(dialog => ({ ...dialog, open: true, successPayment: false, title: t('payment.fail'), content: t('payment.failContent') }));
                            }
                        }
                    }else{
                        if(source === 'wallet'){ // system wallet
                            setDialog(dialog => ({ ...dialog, open: true, successPayment: false, title: t('payment.fail'), content: t('payment.failContent') }));
                        } else {
                            setDialog(dialog => ({ ...dialog, open: true, successPayment: false, title: t('payment.pending'), content: t('payment.pendingContent') }));
                        }
                    }
                }
            })
            .catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        } else {
            setDialog(dialog => ({ ...dialog, open: true, successPayment: false, title: t('payment.fail'), content: t('payment.failContent') }));
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    },[oid, source])

    return (
        <Box className={styles.root2} >
            <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'center', alignItems:'center', width:'100%', paddingTop:10}}>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%' }}>{t('title.payment')}</Typography>
            </Box>
            <Box height={500} paddingBottom={5} display="flex" justifyContent="center" alignItems="center">
                <Typography>Loading...</Typography>
                
                <Dialog open={dialog.open}>
                    <DialogTitle>{ dialog.title }</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{ dialog.content }</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ () => history.replace(`/order?type=${dialog.successPayment ? 'paid' : 'new'}`) }>
                            {t('general.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root2: {
        background: theme.palette.white.mobileBkg,
        minHeight: '100%'
    }
}));
import { useEffect, useRef } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import _ from 'lodash';

function ScrollToTop({ history }) {
    const isMountedRef = useRef();
    const location = useLocation();

    useEffect(() => {
        isMountedRef.current = true;
        const path = _.split(location.pathname, '/');
        let unlisten = () => {};
        
        if(isMountedRef.current) {
            if(!_.includes(['shop'], path[1])) {
                unlisten = history.listen(() => { window.scrollTo(0, 0) });
            }
        }

        return () => { 
            unlisten();
            isMountedRef.current = false;
        }
    }, [history, location.pathname]);

    return (null);
}

export default withRouter(ScrollToTop);
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { Plus } from 'react-feather';
import { ArtTrackOutlined, AspectRatioOutlined } from '@material-ui/icons';

//MUI
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { TextField, Button, Typography, Grid, Box, List, ListItem, Chip, Container, Dialog, DialogActions, DialogContentText, Snackbar, Card, CardContent, DialogContent, DialogTitle, FormControl, Select, FormHelperText, MenuItem } from '@material-ui/core';

//ICON
import { CgTrash } from "react-icons/cg";
import { FiPlus } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import WebpImg from '../../layouts/WebpImg';


const INITIAL_ADDRESS_STATE = { name: '', mobile_code: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "" };

export default function ProfileAddress() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [addresses, setAddress] = useState([]);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [mobileCodeList, setMobileCodeList] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();

    useEffect(() => {

        if (_.size(countries) > 0) {
            if (addressFormState?.country !== '') {
                let codeList = {};
                let countryCode = addressFormState?.mobile_code;
                let code = _.find(countries, { 'code': addressFormState?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (addressFormState?.mobile_code !== '' ? addressFormState?.mobile_code : '');
                    }
                }

                setMobileCodeList(codeList);
                setAddressFormState(prevState => ({ ...prevState, mobile_code: countryCode }));
            } else if (addressFormState?.country === '' && addressFormState?.mobile_code !== '') {
                let tempArr = _.clone(countries);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, addressFormState?.mobile_code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(tempArr[0]['tel_code']);
                    setAddressFormState(prevState => ({ ...prevState, mobile_code: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [countries, addressFormState?.country, addressFormState?.mobile_code]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    const getUserAddress = () => {
        getUrl('user/address').then(addressList => {
            if (isMountedRef.current) {
                setAddress(addressList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;

        getUserAddress();

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if (isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        if (addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });

            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'icFront') {
            if (icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if (name === 'icBack') {
            if (icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        setAddressFormState({ ...addressFormState, [name]: value });
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };


    const deleteUserAddress = () => {
        if (state.deleteAddressId) {
            deleteUrl(`user/address/${state.deleteAddressId}`).then(result => {
                getUserAddress();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            setState({ ...state, deleteAddressId: null, deleteAddressShow: false });
        }
    }

    const openAddAddress = () => {
        setErrorAddressState({});
        setState({ ...state, mode: 'add', modalShow: true });
    };

    const closeModal = () => {
        setState({ ...state, modalShow: false, mode: null, editAddressId: null, });
        setAddressFormState(INITIAL_ADDRESS_STATE);
        setMobileCodeList({});
    };

    const openEditAddress = addressId => {
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': addressId });
        if (editedAddress) {
            const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack , mobile_country_code } = editedAddress;
            let newAddressFormState = {
                name: name || "",
                mobile_code: mobile_country_code || "",
                mobile: mobile || "",
                email: email || "",
                identity_no: identity_no || "",
                address: address || "",
                address2: address2 || "",
                city,
                state,
                zip,
                country,
                icFront,
                icBack,
                default: editedAddress?.default,
            };
            if (_.size(icFront) > 0) {
                newAddressFormState['icFront'] = icFront.file_name;
            }
            if (_.size(icBack) > 0) {
                newAddressFormState['icBack'] = icBack.file_name;
            }

            setAddressFormState(newAddressFormState);
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: addressId }));
        }
    }

    const setDefaultAddress = addressId => {
        const editedAddress = _.find(addresses, { 'id': addressId });

        const { name, mobile, address, address2, city, state, zip, country, email, identity_no , mobile_country_code } = editedAddress;
        const defaultAddress = {
            name, mobile, address, address2, city, state, zip, country, default: 1, email, identity_no , mobile_code: mobile_country_code
        }
        putUrl(`user/address/${addressId}`, defaultAddress).then(result => {
            if (result.error) {
                let errMsg = "";
                _.map(result.error, (errorItem) => {
                    errMsg += errorItem + " ";
                })
                addAlert(errMsg);
            } else {
                addAlert(result.message, 'success');
                getUserAddress();
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const submitAddress = () => {
        // const { country, city, address2, zip, address } = addressFormState;
        // if(!country || !addressFormState.state || !city) {
        //     if(cities) {
        //         if(!address2 || !zip || !address) {
        //             setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        //         }
        //     }
        //     setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        // } else {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setMobileCodeList({});
                    setState(state => ({ ...state, modalShow: false, snackbarShow: true, error: false, message: result.message }));
                    getUserAddress();
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setMobileCodeList({});
                    setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null, snackbarShow: true, error: false, message: result.message }));
                    getUserAddress();
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        // }
    }


    return (
        <div >
            <TitleBar back btnRegister backgroundColor="bg-theme" spendButton />
            <div className='bg-theme p-t-30 p-b-80 header-radius clwhite'>
                <div className='subtitle-box flex-sb-m'>
                    <div className='txt-left'>
                        <p className='fs-header'>
                            {t('profile.addresses')}
                        </p>
                        <p className='fs-subheader cl-label'>
                            {t('profile.addressSubtitle')}
                        </p>
                    </div>

                    <div className='bgwhite translateX flex-c-m' onClick={openAddAddress} style={{ width: '32px', height: '32px', minWidth: '32px', borderRadius: 5 }}>
                        <FiPlus className='cl-theme fs-icon' />
                    </div>
                </div>
            </div>
            <Container>
                <div style={{marginTop:-60, zIndex:2}}>
                    {
                        _.size(addresses) > 0 ?
                        <>
                        {addresses && _.map(addresses, addressItem => (
                            <div className='glass2 shadow-glass2 bor15 p-all-20 m-b-20' key={addressItem.id}>
                                <div>
                                    <div className='flex-sb-m w-full shadow-txt'>
                                        <div className='flex-m' style={{ width: '85%' }}>
                                            <p className='txt_truncate cl-theme fs-title' style={{ maxWidth: '62%', }}>{addressItem.name || '-'}</p>
                                            <p className='fs-content cl-theme p-l-15'>{addressItem.mobile || '-'}</p>
                                        </div>
                                        <div className='cl-theme pointer fs-title' onClick={() => openEditAddress(addressItem.id)} >{t('button.edit')}</div>
                                    </div>
                                    <div className='cl-text fs-content shadow-txt'>
                                        <p>{addressItem.email || '-'}</p>
                                        <p>{`${addressItem.address},`} {`${addressItem.address2_display ? addressItem.address2_display + ', ' : ''} ${addressItem.city_display}, ${addressItem.state_display},`} {`${addressItem.zip}, ${addressItem.country_display}`}</p>
                                    </div>
                                </div>
                                <div className='flex-sb-m p-t-15'>
                                    {
                                        addressItem.default ?
                                            <div style={{ borderRadius: 48, padding: '5px 9px', height: 'unset', width: 100, boxShadow: 'inset 3px 4px 3px 0 #00000029', backgroundColor: '#dcdcdc' }}
                                                className="fs-content" >
                                                <p className='fs-content cl-text txt-center'>{t('button.default')}</p>
                                            </div> :
                                            <div className='btn-theme' onClick={() => { setDefaultAddress(addressItem.id) }}>{t('button.setDefault')}</div>
                                    }
                                    <div className='pointer' onClick={() => setState({ ...state, deleteAddressId: addressItem.id, deleteAddressShow: true })} ><CgTrash className='fs-icon cl-theme' /></div>
                                </div>
                        </div>
                    ))}
                        </>
                    :
                            <div>
                                {/* <div className='w-full flex-r-m p-r-50' style={{ transform: 'rotate(352deg)', transformOrigin: 'bottom' }}>
                                    <div style={{ width: 50 }}>
                                        <img src='/images/empty/arrow.png' alt='arrow' className='w-full' />
                                    </div>
                                </div> */}
                                <div className='empty-img'>
                                    <img src="/images/empty/address.png" className='w-full' alt="empty" />
                                </div>
                                <div style={{ width: 350, margin: '0 auto', maxWidth: '85%' }}>
                                    <p className='fs-content cl-label txt-center'>
                                        {t('profile.addressEmpty')}
                                    </p>
                                </div>
                            </div>
                    }
               
                </div>
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
                <Dialog
                    open={state.deleteAddressShow}
                    fullWidth
                    onClose={() => setState({ ...state, deleteAddressShow: false })}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('address.removeTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('address.removeContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant="outlined" onClick={() => setState({ ...state, deleteAddressShow: false, deleteAddressId: null })} color="default">
                            <Typography variant="overline">{t('button.cancel')}</Typography>
                        </Button>
                        <Button onClick={deleteUserAddress} variant="contained" color="secondary">
                            <Typography variant="overline">{t('button.confirm')}</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={state.modalShow} maxWidth='sm' onClose={closeModal} fullWidth>
                    <DialogTitle>
                        <div className='flex-sb-m'>
                            <p className='fs-title cl-theme'>
                                {
                                    state.mode === 'add' ?
                                        t('address.addNewAddress')
                                        : t('address.editAddress')
                                }
                            </p>
                            <IoMdCloseCircleOutline className='cl-theme fs-icon pointer' onClick={closeModal} />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className='divider-white p-tb-4' />
                        <div className='p-t-25'>
                            <p className='fs-textfield' >{t('address.recipient')}</p>
                            <TextField
                                onChange={handleAddressChange}
                                fullWidth
                                variant="filled"
                                size="small"
                                type="text"
                                inputProps={{ name: 'name' }}
                                value={addressFormState.name}
                                error={errorAddressState.name ? true : false}
                                helperText={errorAddressState.name}
                            />
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.country')}</p>
                            <Select
                                native
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={addressFormState.country}
                                onChange={handleAddressChange}
                                inputProps={{ name: 'country' }}
                                error={errorAddressState.country ? true : false}
                            >
                                <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                                {
                                    _.map(countries, countryItem => {
                                        return (
                                            <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText>{errorAddressState.country}</FormHelperText>
                           
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.mobile')}</p>
                            <div className='flex-sb-m w-full' style={{ flexWrap: 'wrap' }}>
                                <div style={{ width: 'calc(30% - 10px)' }}>
                                    <TextField
                                        native="true"
                                        select
                                        // label={t('register.code')}
                                        value={addressFormState.mobile_code}
                                        onChange={handleAddressChange}
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        error={errorAddressState?.mobile_code ? true : false}
                                        disabled = { _.size(mobileCodeList) > 0 ? false:true}
                                    >
                                        {
                                            _.size(mobileCodeList) > 0 ?
                                             _.map(mobileCodeList, (option, key) => {
                                                return (
                                                    <MenuItem key={key} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                )
                                            }):
                                             <MenuItem value="" >{``}</MenuItem>
                                        }
                                    </TextField>
                                </div>
                                <div className='txt-center' style={{ width: 20 }}>
                                    <p className='fs-21'>-</p>
                                </div>
                                <div style={{ width: 'calc(70% - 10px)' }}>
                                    <TextField
                                        onChange={handleAddressChange}
                                        variant="filled"
                                        size="small"
                                        type="text"
                                        fullWidth
                                        inputProps={{ name: 'mobile' }}
                                        value={addressFormState.mobile}
                                        error={errorAddressState.mobile ? true : false}
                                        // helperText={errorAddressState.mobile}
                                    />
                                </div>
                            </div>
                            {
                                addressFormState?.mobile_code > 0 ? <></> : <FormHelperText className="p-l-10">{t('general.pleaseSelectCountry')}</FormHelperText>
                            }
                            <FormHelperText style={{ color: 'red' }} >{_.join([errorAddressState?.mobile_code , errorAddressState?.mobile],'')}</FormHelperText>
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.email')}</p>
                            <TextField
                                onChange={handleAddressChange}
                                fullWidth
                                variant="filled"
                                size="small"
                                type="text"
                                inputProps={{ name: 'email' }}
                                value={addressFormState.email}
                                error={errorAddressState.email ? true : false}
                                helperText={errorAddressState.email}
                            />
                        </div>
                        {shouldUploadIC &&
                            <>
                                <div className='p-t-25'>
                                    <p className='fs-textfield'>{t('address.identity_no')}</p>
                                    <TextField
                                        onChange={handleAddressChange}
                                        fullWidth
                                        variant="filled"
                                        size="small"
                                        type="text"
                                        inputProps={{ name: 'identity_no' }}
                                        value={addressFormState.identity_no}
                                        error={errorAddressState.identity_no ? true : false}
                                        helperText={errorAddressState.identity_no}
                                    />
                                </div>
                                <div className='p-t-25'>
                                    <p className='fs-textfield' >{t('checkout.chinaRecipientNeedIC')}</p>
                                    <Box display="flex" flexDirection="row">
                                        <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                        <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icFrontRef.current.click()}
                                            variant="outlined"
                                        // startIcon={ addressFormState.icFront ? <Avatar src={ addressFormState.icFront } /> : <ArtTrackOutlined /> }
                                        >
                                            <div className='flex-c-m flex-col w-full'>
                                                {addressFormState.icFront ?
                                                    <img src={addressFormState.icFront} alt="ic front" />
                                                    : <ArtTrackOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icFront')}</Typography>
                                            </div>
                                        </Button>
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icBackRef.current.click()}
                                            variant="outlined"
                                        // startIcon={ addressFormState.icBack ? <Avatar src={ addressFormState.icBack } /> : <AspectRatioOutlined /> }
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                {addressFormState.icBack ?
                                                    <img src={addressFormState.icBack} alt="ic back" />
                                                    : <AspectRatioOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icBack')}</Typography>
                                            </Box>
                                        </Button>
                                    </Box>
                                    <Typography variant="caption" color="error">{errorAddressState.icFront}</Typography>
                                    <Typography variant="caption" color="error">{errorAddressState.icBack}</Typography>
                                </div>
                            </>
                        }
                      
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.zip')}</p>
                            <TextField
                                inputProps={{ name: 'zip' }}
                                onChange={handleAddressChange}
                                variant="filled"
                                type="text"
                                size="small"
                                fullWidth
                                value={addressFormState.zip}
                                error={errorAddressState.zip ? true : false}
                                helperText={errorAddressState.zip}
                            />
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.state')}</p>
                            {
                                _.includes(addressGroup.stateGroup, addressFormState.country) ?
                                    <>
                                        <Select
                                            native
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            value={addressFormState.state}
                                            onChange={handleAddressChange}
                                            inputProps={{ name: 'state' }}
                                            error={errorAddressState.state ? true : false}
                                        >
                                            <option value="">{t('address.pleaseSelect')}{t('address.state')}</option>
                                            {
                                                _.map(addressState, stateItem => {
                                                    return (
                                                        <option key={stateItem.id} value={stateItem.id}>{stateItem.name_display}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>{errorAddressState.state}</FormHelperText>
                                    </>
                                    :
                                    <TextField
                                        onChange={handleAddressChange}
                                        variant="filled"
                                        size="small"
                                        type="text"
                                        fullWidth
                                        inputProps={{ name: 'state' }}
                                        value={addressFormState.state}
                                        error={errorAddressState.state ? true : false}
                                        helperText={errorAddressState.state}
                                    />
                            }
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.city')}</p>
                            {
                                _.size(cities) > 0 ?
                                    <>
                                        <Select
                                            native
                                            variant="filled"
                                            size="small"
                                            value={addressFormState.city}
                                            onChange={handleAddressChange}
                                            inputProps={{ name: 'city' }}
                                            fullWidth
                                            error={errorAddressState.city ? true : false}
                                        >
                                            <option value="">{t('address.pleaseSelect')}{t('address.city')}</option>
                                            {
                                                _.map(cities, cityItem => {
                                                    return (
                                                        <option key={cityItem.id} value={cityItem.id}>{cityItem.name_display}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>{errorAddressState.city}</FormHelperText>
                                    </>
                                    :
                                    <TextField
                                        onChange={handleAddressChange}
                                        variant="filled"
                                        size="small"
                                        type="text"
                                        fullWidth
                                        inputProps={{ name: 'city' }}
                                        value={addressFormState.city}
                                        error={errorAddressState.city ? true : false}
                                        helperText={errorAddressState.city}
                                    />
                            }
                        </div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.address2')}</p>
                            {
                                _.size(district) > 0 ?
                                    <>
                                        <Select
                                            native
                                            variant="filled"
                                            size="small"
                                            value={addressFormState.address2}
                                            onChange={handleAddressChange}
                                            inputProps={{ name: 'address2' }}
                                            fullWidth
                                            error={errorAddressState.address2 ? true : false}
                                        >
                                            <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                            {
                                                _.map(district, districtItem => {
                                                    return (
                                                        <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                    </>
                                    :
                                    <TextField
                                        onChange={handleAddressChange}
                                        variant="filled"
                                        size="small"
                                        type="text"
                                        fullWidth
                                        inputProps={{ name: 'address2' }}
                                        value={addressFormState.address2}
                                        error={errorAddressState.address2 ? true : false}
                                        helperText={errorAddressState.address2}
                                    />
                            }
                        </div>

                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('address.address')}</p>
                            <TextField
                                onChange={handleAddressChange}
                                variant="filled"
                                size="small"
                                type="text"
                                fullWidth
                                inputProps={{ name: 'address' }}
                                value={addressFormState.address}
                                error={errorAddressState.address ? true : false}
                                helperText={errorAddressState.address}
                                multiline
                                rows={3}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                            <div className='p-lr-20 w-full'>
                                <div className='btn-theme w-full translateY' onClick={submitAddress}>
                                    <p className='fs-button'>
                                        {state.mode === 'add' ? t('button.add') : t('button.update')}
                                    </p>
                                </div>
                            </div>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 15
    },
    formControl: {
        padding: 5
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none',
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    textFieldTitle: {
        color: theme.palette.gray.dtext,
        fontSize: 14,
        textTransform: 'uppercase',
        paddingBottom: 5,
        paddingLeft: 10
    }
}));

import {
    ADD_ERROR, CHANGE_LANGUAGE,CHANGE_CURRENCY,CHANGE_CURRENCY_RATE ,STORE_UUID, REMOVE_UUID, CART_TOTAL, CODE_STATE, RESET_CODE_STATE, AUTH_SUCCESS, AUTH_FAIL, LINK_REFERRAL_CODE, SHARE_LINK_PRODUCT
} from './types';

export const addError = data => dispatch => {
    dispatch({ type: ADD_ERROR, payload: data });
}

export const changeLanguage = lang => dispatch => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
}

export const changeCurrency = curr => dispatch => {
    dispatch({ type: CHANGE_CURRENCY, payload: curr });
}

export const changeCurrencyRate = currate => dispatch => {
    dispatch({ type: CHANGE_CURRENCY_RATE, payload: currate });
}

export const storeUuid = uuid => dispatch => {
    dispatch({ type: STORE_UUID, payload: uuid });
}

export const removeUuid = () => dispatch => {
    dispatch({ type: REMOVE_UUID });
}

export const updateCartTotal = total => dispatch => {
    dispatch({ type: CART_TOTAL, payload: total });
}

export const storeCodeState = (code, state) => dispatch => {
    dispatch({ type: CODE_STATE, payload: {code, state} });
}

export const resetCodeState = () => dispatch => {
    dispatch({ type: RESET_CODE_STATE });
}

export const authSuccess = authData => dispatch => {
    dispatch({ type: AUTH_SUCCESS, payload: authData });
}

export const authFail = () => dispatch => {
    dispatch({ type: AUTH_FAIL });
}

export const updateShareLinkRef = refCode => dispatch => {
    dispatch({ type: LINK_REFERRAL_CODE, payload: refCode });
}

export const updateShareLinkProduct = linkProductId => dispatch => {
    dispatch({ type: SHARE_LINK_PRODUCT, payload: linkProductId });
}
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Button, Dialog, DialogContent, Slide, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { currencyFormat } from '@helper/Tools';

export default function TransactionDialog(props) {
    const { dialogOpen, closeDialog, dialogInfo } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    return (
        <Dialog
            open={dialogOpen}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            style={{ width: '547px', maxWidth: '100%', margin: '0 auto' }}
            onClose={closeDialog}
        >
            <DialogContent >
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}>{t('transaction.amount')}</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24, color: (dialogInfo.factor > 0 ? "green" : 'red'), marginLeft: '-15px' }}>{`${dialogInfo.factor > 0 ? "+" : "-"} ${currencyFormat(dialogInfo.amount || 0)}`}</Typography>
                </Box>
                <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.transaction')}</Typography>
                    <Typography style={{ fontSize: 14 }}>{_.hasIn(dialogInfo.type_display, i18n.language) ? dialogInfo.type_display[i18n.language] : '-'}</Typography>
                </Box>
                <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('transaction.remark')}</Typography>
                    <Typography style={{ fontSize: 14 }}>{dialogInfo.remark}</Typography>
                </Box>
                <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                    <Box display="flex" width="100%">
                        <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.dateTime')}</Typography>
                        <Typography style={{ fontSize: 14, flex: 2 }}>{dialogInfo.created_at}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                        <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.idNo')}</Typography>
                        <Typography style={{ fontSize: 14, flex: 2 }}>{_.size(dialogInfo.transaction_details) > 0 ? dialogInfo?.transaction_details[0]?.transaction_id : '-'}</Typography>
                    </Box>
                </Box>
            </DialogContent>
            <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={closeDialog}>
                {t('transaction.done')}
            </Button>
        </Dialog>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20
    },
}));
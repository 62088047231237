import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { getUrl, postUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';
import { currencyFormat } from '@helper/Tools'
import { storeWalletBalance } from '@actions';

//MUI
import { Box, Typography, TextField, Button, Select, InputLabel, FormControl, MenuItem, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//ICON
import { FiChevronRight } from "react-icons/fi";


//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

const DEFAULT_ERROR_FIELD = { amount: "", wallet: "", remark: "", security_password: "" };
// const DEFAULT_ERROR_FIELD = { amount: "", wallet: "", remark: "", password: "" };

export default function Convert() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [convertWalletInfo, setConvertWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", wallet: "", remark: "", security_password: "", dateTime: "", id: "" });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [convertWalletList, setConvertWalletList] = useState([]);
    const [convertStatus, setConvertStatus] = useState("insert"); // insert -> review -> result
    const [convertRate, setConvertRate] = useState(0);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance, username } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        username: state.user.username
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        if (!existWallet || existWallet.allowConvert === false) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if (_.size(walletInfo) > 0) {
            getUrl('wallets/rules').then(response => {
                if (response.data && isMountedRef.current) {
                    let selectedWalletRule = [];
                    _.map(response.data.self, (o, i) => {
                        if (i === String(walletInfo.id)) {
                            selectedWalletRule = o;
                        }
                    });

                    let selectedWalletCovertList = [];
                    _.map(selectedWalletRule, walletId => {
                        const selectedWalletInfo = _.find(walletList, { id: walletId });
                        selectedWalletCovertList.push(selectedWalletInfo);
                    })
                    setConvertWalletList(selectedWalletCovertList);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }

        return () => isMountedRef.current = false;
    }, [walletInfo, walletList, addAlert]);

    useEffect(() => {
        if (state.wallet && walletList) {
            const selectedWallet = _.find(walletList, { id: state.wallet });
            setConvertWalletInfo(selectedWallet);
        }
    }, [state.wallet, walletList]);

    useEffect(() => {
        let rate = 0;
        if (walletInfo.id > 0 && convertWalletInfo.id > 0) {
            rate = 1;
            _.map(walletList, wallet => {
                if (wallet.id === walletInfo.id) {
                    if (!wallet.is_cash && wallet.cash_rate > 0) {
                        rate = rate * wallet.cash_rate;
                    }
                }
                if (wallet.id === convertWalletInfo.id) {
                    if (!wallet.is_cash && wallet.cash_rate > 0) {
                        rate = rate / wallet.cash_rate;
                    }
                }
            })
        }
        setConvertRate(rate);
    }, [walletList, walletInfo, convertWalletInfo]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if (name === 'amount') {
            if (parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }

        setState({ ...state, [name]: newValue });
    }

    const reviewConvert = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "security_password"], field => {
            // _.map(["amount", "password"], field => {
            if (_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('convert.isRequired', { field: t(`convert.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        })

        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if (!error) {
            setConvertStatus('review')
        }
    }

    const convertUser = () => {
        const { amount, remark, security_password } = state;
        // const { amount, remark, password } = state;
        const convertData = {
            source: 'local',
            login_method: username,
            to_wallet_id: convertWalletInfo.id,
            amount,
            remark,
            security_password,
            // password,
        };

        postUrl(`wallets/${walletInfo.id}/transfer`, convertData).then(response => {
            if (response.status) {
                const { created_at, transaction_id, data } = response;
                setConvertStatus('result');
                setState({ ...state, id: transaction_id, dateTime: created_at });
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchWallet();
            } else {
                if (response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if (response.data) {
                    addAlert(JSON.stringify(response.data));
                }
                setConvertStatus('insert')
            }
        }).catch(error => {
            setConvertStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }

    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if (response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    return (
        <div className='min-h-100vh'>
            <TitleBar backgroundColor="bg-theme" back /*currencyButton*/ spendButton />
            {
                convertStatus === 'insert' ?
                    <>
                        <div className={`bg-theme p-b-200 header-radius`}></div>
                        <div style={{ marginTop: -165 }} >
                            <div className='p-lr-15 p-tb-20'>
                                <div className='glass3 p-t-20 p-lr-20 p-b-80 flex-c-m' style={{ borderRadius: 21, width: 350, maxWidth: '85%', margin: '0 auto' }}>
                                    <div className='flex-sb-m w-full'>
                                        <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                                            <p className='fs-12 txt-upper'>{t('convert.convertableBalance')}</p>
                                            <p className='fs-24'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                                        </div>
                                        <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
                                            <p className='fs-30 txt-upper'><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='gradient-blue bor5 pos-relative p-tb-10 menu-shadow' style={{ marginTop: -50 }}>
                                    <Box className={classes.boxContainer}>
                                        <div className='flex-sa-m w-full'>
                                            <div className='clwhite txt-center'>
                                                <p className='fs-14'>{_.size(walletInfo) ? walletInfo.wallet_name : ''}</p>
                                                <p className='fs-21'>{currencyFormat(parseFloat(_.size(state.amount) ? state.amount : '0'))}</p>
                                            </div>
                                            <FiChevronRight className='fs-21 clwhite' />
                                            <div className='clwhite txt-center'>
                                                <p className='fs-14'>{_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : '-'}</p>
                                                <p className='fs-21'>{currencyFormat(state.amount * convertRate)}</p>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className={`bg-theme p-b-300 header-radius`}>
                        <Container>
                            {convertStatus === 'result' &&
                                <div>
                                    <p className='txt-center fs-header clwhite p-t-30'>{t('general.successfully')}</p>
                                    <div className='subtitle-box'>
                                        <p className='cl-label fs-subheader lh-11 txt-center'>{t('convert.successfullyConvertSubtitle')}</p>
                                    </div>
                                </div>
                            }
                            {convertStatus === 'review' &&
                                <div>
                                    <p className='txt-center fs-header clwhite p-t-30'>{t('convert.review')}</p>
                                    <div className='subtitle-box'>
                                        <p className='cl-label fs-subheader lh-11 txt-center'>{t('convert.reviewSubtitle')}</p>
                                    </div>
                                </div>
                            }
                        </Container>
                    </div>
            }

            <Container>
                {
                    convertStatus === 'insert' ?
                        <div>
                            <div className='cl-theme fs-21 p-t-20 flex-m'>
                                <p><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
                                {_.size(convertWalletInfo) ?
                                    <>
                                        <FiChevronRight className='fs-21 cl-theme m-lr-5' />
                                        <p><b>{_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : '-'}</b></p>
                                    </>
                                    :
                                    null
                                }
                                <p className='p-l-5'><b>{t('title.walletConvert')}</b></p>
                            </div>
                            <div className='p-b-25'>
                                <TextField
                                    type="text"
                                    variant="filled"
                                    value={state.amount}
                                    name="amount"
                                    onChange={handleChange}
                                    classes={{ root: classes.textFieldRoot }}
                                    label={t('convert.convertAmount')}
                                    fullWidth
                                    error={_.size(errorField.amount) ? true : false}
                                    helperText={errorField.amount}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                                <FormControl variant="filled" className={classes.formControl} style={{ marginTop: 15, marginBottom: 15 }}>
                                    <InputLabel id="wallet-to-convert">{t('convert.wallet')}</InputLabel>
                                    <Select
                                        labelId="wallet-to-convert"
                                        value={state.wallet}
                                        name="wallet"
                                        onChange={handleChange}
                                        label={t('convert.wallet')}
                                    >
                                        <MenuItem value="" disabled ><em>None</em></MenuItem>
                                        {_.map(convertWalletList, walletItem => (
                                            <MenuItem key={walletItem.code} value={walletItem.id}>{walletItem.wallet_name}</MenuItem>
                                        ))}
                                    </Select>
                                    <span style={{ fontSize: 12, color: 'red' }}>{errorField.wallet}</span>
                                </FormControl>

                                <TextField
                                    type="text"
                                    variant="filled"
                                    value={state.remark}
                                    name="remark"
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.textFieldRoot
                                    }}
                                    label={t('convert.remark')}
                                    fullWidth
                                    autoComplete="off"
                                    error={_.size(errorField.remark) ? true : false}
                                    helperText={errorField.remark}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />

                                <TextField
                                    type="password"
                                    variant="filled"
                                    value={state.security_password}
                                    name="security_password"
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.textFieldRoot
                                    }}
                                    label={t('convert.security_password')}
                                    fullWidth
                                    error={_.size(errorField.security_password) ? true : false}
                                    helperText={errorField.security_password}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                            </div>
                            <div className='btn-theme w-full' onClick={reviewConvert}>
                                <p className='fs-button'>{t('button.next')}</p>
                            </div>
                        </div>
                        :
                        <div className='bg-base bor10 p-tb-20' style={{ marginTop: -270, boxShadow: '0px 4px 6px 0px rgba(112, 143, 223, 0.25)' }}>
                            <div className='txt-center p-t-20 p-b-20 p-lr-20'>
                                <p className='fs-content cl-label'>{t('convert.convertAmount')}</p>
                                <p className='fs-35 cl-theme'>
                                    <b>{`${currencyFormat(state.amount)} ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}</b>
                                </p>
                            </div>
                            <div className='w-full flex-sb-m'>
                                <div className='bg-theme bor50' style={{ width: 30, height: 30, marginLeft: -15 }} />
                                <div className='w-full' style={{ backgroundImage: 'url(/images/general/receipt/dash-theme.png)', backgroundRepeat: 'repeat-x', height: 4, backgroundPosition: 'center' }}></div>
                                <div className='bg-theme bor50' style={{ width: 30, height: 30, marginRight: -15 }} />
                            </div>
                            <div className='p-lr-20 p-t-20'>
                                <div>
                                    <p className='fs-content'>{t('convert.wallet')}</p>
                                    <p className='fs-title'>{`${t('convert.from')}  ${_.size(walletInfo) ? walletInfo?.wallet_name : ''} ${t('convert.to')} ${_.size(convertWalletInfo) ? convertWalletInfo?.wallet_name : ''} `}</p>
                                </div>
                                <div className='fs-content p-tb-10'>
                                    <p>{t('convert.remark')}</p>
                                    <p style={{ wordWrap: "break-word" }}>{state.remark}</p>
                                </div>
                                {
                                    convertStatus === 'result' &&
                                    <>
                                        <div className='fs-content p-tb-10'>
                                            <p>{t('convert.idNo')}</p>
                                            <p>{state.id}</p>
                                        </div>
                                        <div className='fs-content p-tb-10'>
                                            <p>{t('convert.dateTime')}</p>
                                            <p>{state.dateTime}</p>
                                        </div>
                                    </>
                                }

                                <div className='p-t-25'>
                                    {
                                        convertStatus === 'review' &&
                                        <div className='w-full flex-sb-m'>
                                            <div className='btn-outlined-theme' onClick={() => setConvertStatus('insert')} style={{ width: '47%' }}>
                                                <p className='fs-button'>{t('button.edit')}</p>
                                            </div>
                                            <div className='btn-theme' onClick={convertUser} style={{ width: '47%' }}>
                                                <p className='fs-button'>{t('convert.convertNow')}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        convertStatus === 'result' &&
                                        <div className='btn-theme w-full' onClick={() => history.push('/wallet-home')} >
                                            <p className='fs-button'>{t('convert.complete')}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Container>
        </div >
        // old design
        // <div>
        //     <TitleBar backgroundColor="bg-theme" back /*currencyButton*/  spendButton />    

        //     <div className='bg-theme p-b-200 header-radius'></div>

        //     <Container>
        //         <div style={{ marginTop: -165 }}>
        //             <div className='p-lr-15 p-tb-20'>
        //                 <div className='glass3 p-t-20 p-lr-20 p-b-80 flex-c-m' style={{ borderRadius: 21, width: 350, maxWidth: '85%', margin: '0 auto' }}>
        //                     <div className='flex-sb-m w-full'>
        //                         <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
        //                             <p className='fs-12 txt-upper'>{t('convert.convertableBalance')}</p>
        //                             <p className='fs-24'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
        //                         </div>
        //                         <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
        //                             <p className='fs-30 txt-upper'><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className='gradient-blue bor5 pos-relative p-tb-10 menu-shadow' style={{ marginTop: -50 }}>
        //                     {/* {convertStatus !== 'result' && */}
        //                         <Box className={classes.boxContainer}>
        //                             <div className='flex-sa-m w-full'>
        //                                 <div className='clwhite txt-center'>
        //                                     <p className='fs-14'>{_.size(walletInfo) ? walletInfo.wallet_name : ''}</p>
        //                                     <p className='fs-21'>{currencyFormat(parseFloat(_.size(state.amount) ? state.amount : '0'))}</p>
        //                                 </div>
        //                                 <FiChevronRight className='fs-21 clwhite' />
        //                                 <div className='clwhite txt-center'>
        //                                     <p className='fs-14'>{_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : '-'}</p>
        //                                     <p className='fs-21'>{currencyFormat(state.amount * convertRate)}</p>
        //                                 </div>
        //                             </div>
        //                         </Box>
        //                     {/* } */}
        //                 </div>
        //             </div>
        //         </div>
        //     </Container>

        //     <Container>
        //     {convertStatus === 'result' &&
        //         <div style={{ marginTop: 20 }}>
        //             <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{t('convert.successfullyConvert')}</Typography>
        //             <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" >
        //                 <Box flex={1}>
        //                     <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{t('convert.from')}</Typography>
        //                     <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{t('convert.to')}</Typography>
        //                 </Box>
        //                 <Box flex={2}>
        //                     <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>{currencyFormat(state.amount)}</span> {_.size(walletInfo) ? walletInfo.wallet_name : ''}</Typography>
        //                     <Typography variant="h6" style={{ color: theme.palette.secondary.main, fontSize: 20 }}><span style={{ fontWeight: 'bold' }}>{currencyFormat(state.amount * convertRate)}</span> {_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : ''}</Typography>
        //                 </Box>
        //             </Box>
        //             <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="column" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
        //                 <Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.remark')}</Typography>
        //                 <Typography variant="h6" style={{ fontSize: 12, wordWrap: "break-word" }}>{state.remark}</Typography>
        //             </Box>
        //             <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
        //                 <Box paddingRight={1}>
        //                     <Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.dateTime')}</Typography>
        //                     <Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.idNo')}</Typography>
        //                 </Box>
        //                 <Box flex={1}>
        //                     <Typography variant="h6" style={{ fontSize: 12 }}>{state.dateTime}</Typography>
        //                     <Typography variant="h6" style={{ fontSize: 12 }}>{state.id}</Typography>
        //                 </Box>
        //             </Box>
        //         </div>
        //     }
        //     {convertStatus !== 'result' && 

        //     <Box width="100%">
        //     <div className='cl-theme fs-21 p-t-20 flex-m'>
        //     <p><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
        //     {_.size(convertWalletInfo) ?
        //     <>
        //     <FiChevronRight className='fs-21 cl-theme m-lr-5' />
        //     <p><b>{_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : '-'}</b></p>
        //     </>
        //     :
        //     null
        //     }
        //     <p className='p-l-5'><b>{t('title.walletConvert')}</b></p>

        //     </div>
        //         <TextField
        //             type="text"
        //             variant="filled"
        //             value={state.amount}
        //             name="amount"
        //             onChange={handleChange}
        //             classes={{ root: classes.textFieldRoot }}
        //             InputProps={{ readOnly: convertStatus === 'review' ? true : false }}
        //             label={t('convert.convertAmount')}
        //             fullWidth
        //             error={_.size(errorField.amount) ? true : false}
        //             helperText={errorField.amount}
        //             FormHelperTextProps={{ color: 'red', fontSize: 12 }}
        //         />
        //         <FormControl variant="filled" className={classes.formControl} style={{ marginTop: 15, marginBottom: 15}}>
        //             <InputLabel id="wallet-to-convert">{t('convert.wallet')}</InputLabel>
        //             <Select
        //                 labelId="wallet-to-convert"
        //                 value={state.wallet}
        //                 name="wallet"
        //                 onChange={handleChange}
        //                 label={t('convert.wallet')}
        //                 readOnly={convertStatus === 'review' ? true : false}
        //             >
        //                 <MenuItem value=""><em>None</em></MenuItem>
        //                 {_.map(convertWalletList, walletItem => (
        //                     <MenuItem key={walletItem.code} value={walletItem.id}>{walletItem.wallet_name}</MenuItem>
        //                 ))}
        //             </Select>
        //             <span style={{ fontSize: 12, color: 'red' }}>{errorField.wallet}</span>
        //         </FormControl>

        //         <TextField
        //             type="text"
        //             variant="filled"
        //             value={state.remark}
        //             name="remark"
        //             onChange={handleChange}
        //             classes={{
        //                 root: classes.textFieldRoot
        //             }}
        //             InputProps={{
        //                 readOnly: convertStatus === 'review' ? true : false,
        //             }}
        //             label={t('convert.remark')}
        //             fullWidth
        //             error={_.size(errorField.remark) ? true : false}
        //             helperText={errorField.remark}
        //             FormHelperTextProps={{ color: 'red', fontSize: 12 }}
        //         />

        //         <TextField
        //             type="password"
        //             variant="filled"
        //             value={state.security_password}
        //             name="security_password"
        //             onChange={handleChange}
        //             classes={{
        //                 root: classes.textFieldRoot
        //             }}
        //             InputProps={{
        //                 readOnly: convertStatus === 'review' ? true : false,
        //             }}
        //             label={t('convert.security_password')}
        //             fullWidth
        //             error={_.size(errorField.security_password) ? true : false}
        //             helperText={errorField.security_password}
        //             FormHelperTextProps={{ color: 'red', fontSize: 12 }}
        //         />
        //     </Box>}
        //     <div className='p-t-15'>
        //         {convertStatus === 'insert' && <div className='btn-theme w-full' onClick={reviewConvert}>
        //             <p className='fs-16 p-tb-5'>{t('convert.convertNow')}</p>
        //         </div>}
        //         {convertStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
        //             <div className='btn-theme' onClick={() => setConvertStatus('insert')} style={{ width: '45%' }}>
        //                 {t('convert.changeDetail')}
        //             </div>
        //             <div className='btn-theme' onClick={convertUser} style={{ width: '45%' }}>
        //                 {t('convert.confirmConvert')}
        //             </div>
        //         </Box>}
        //         {convertStatus === 'result' && <div className='btn-theme w-full' onClick={() => history.push('/wallet-home')}>
        //             <p className='fs-16 p-tb-5'>{t('convert.complete')}</p>
        //         </div>}
        //     </div>
        //     </Container>
        // </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 10px',
    },
    balanceStyle: {
        fontSize: 30,
        color: "white",
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white,
        fontSize: 11,
        letterSpacing: 1
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15,
        marginBottom: 30
    },
}));

const styles = {
    inputPropsStyle: {
        textAlign: 'center',
        fontSize: 36,
        fontWeight: 'bold',
    }
}
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// import Pagination from '@material-ui/lab/Pagination';
// import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@helper/Tools';
import moment from 'moment';

import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//MUI
import { Grid, Typography, Box, Link, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//ICON
import { HiMiniBellAlert } from "react-icons/hi2";
import { BiBell } from "react-icons/bi";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';




export default function Notification() {

    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const { t, i18n } = useTranslation();
    const styles = useStyles();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const query = useQuery();
    const messagetype = query.get("mt") && query.get("mt") !== '' ? query.get("mt") : '';

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`notifications?mt=${messagetype}&&page=${page}`).then(response => {
            if (response.status) {
                if (isMountedRef.current) {
                    setNotifications(prevNotifications => [
                        ...prevNotifications,
                        ...response.data.data
                    ]);
                    setTotalPage(response.data.last_page);
                    setLoading(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, addAlert]);

    const loadTransaction = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" spendButton />
            <div className='bg-theme p-b-100 header-radius txt-center'>
                <div className='subtitle-box'>
                    <p className='fs-header clwhite p-t-30'><b>{t('message.promotion')}</b></p>
                    <p className='cl-label fs-subheader lh-11'>
                        {t('notification.systemSubtitle')}</p>
                </div>
            </div>

            <div style={{ marginTop: -50 }}>
                <Container>
                    {

                        _.size(notifications) > 0 ?
                            <div> {
                                _.map(notifications, notificationItem => {
                                    let formattedDate = moment(notificationItem['updated_at']).format("YYYY-MM-DD");
                                    return (
                                        <Link key={notificationItem.id} underline='none' color="inherit" to={notificationItem.type === 'order' ? `/account/order/${notificationItem.linking_array.orderNo}` : `/notification-detail/${notificationItem.id}`} component={RouterLink}>
                                            {notificationItem.pivot.read_at ?
                                                <div className='glass2 bor15 p-tb-15 p-lr-15 m-b-20 shadow-glass2'>
                                                    <Grid container>
                                                        <Grid item xs={2}  >
                                                            <Box display="flex" alignItems="center" justifyContent="center" height="100%"  >
                                                                {
                                                                    notificationItem.type == 'order' ?
                                                                        <WebpImg src={notificationItem.linking_array.orderImage} style={{ height: 40, width: 40, objectFit: 'contain' }} alt={notificationItem.linking_array.orderNo} />
                                                                        :
                                                                        <BiBell className='fs-icon cl-theme' />
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} className='shadow-txt'>
                                                            <p className='fs-title cl-theme txt_truncate'>{notificationItem[`subject_${i18n.language}`]}</p>
                                                            <p className='fs-remark cl-theme' >{formattedDate}</p>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                :
                                                <div className='glass2 bor15 p-tb-15 p-lr-15 m-b-20 shadow-glass2'>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                                                {
                                                                    notificationItem.type == 'order' ?
                                                                        <WebpImg src={notificationItem.linking_array.orderImage} style={{ height: 40, width: 40, objectFit: 'contain' }} alt={notificationItem.linking_array.orderNo} />
                                                                        :
                                                                        <HiMiniBellAlert className='fs-icon cl-theme' />
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} className='shadow-txt'>
                                                            <p className='fs-title cl-theme txt_truncate'>{notificationItem[`subject_${i18n.language}`]}</p>
                                                            <p className='fs-content cl-theme'>{formattedDate}</p>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                        </Link>
                                    )
                                })

                            }
                                <Grid container spacing={1} justify="center" style={{ marginTop: 15 }}>
                                    {
                                        page < totalPage
                                            ? <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                                            : <Typography style={{ textAlign: 'center' }}>{t('transaction.endOfList')}</Typography>
                                    
                                    }
                                </Grid>
                            </div>
                            :
                            <div>
                                <div style={{ margin: '0 auto', width: 150 }}>
                                    <img src='/images/empty/promo.png' alt="empty" />
                                </div>
                                <p className='cl-label txt-center fs-16'>
                                    {t('notification.noNotification')}
                                </p>
                            </div>
                    }
                </Container>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        //padding: '10px 0px 30px 0px',
        //background: theme.palette.white.mobileBkg, 
        padding: '0 0 80px',
        minHeight: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
}));
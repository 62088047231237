import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, IconButton, InputAdornment, TextField, Container, Typography, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FiEye, FiEyeOff, FiKey, FiLock } from "react-icons/fi";

import { storeProfile } from '@actions';
import { putUrl, getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

const INITIAL_STATE = {
    cryptoAddress: "",
    // walletType: "",
    securityPassword: "",
    authenticationCode: "",
};

const ProfileCryptoAddress = () => {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { wallets } = useSelector(state => state.user);
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState({});

    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    // submit data
    const updateCryptoAddress = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            crypto_address: state?.cryptoAddress,
            // wallet_type: state.walletType,
            security_password: state?.securityPassword,
            authentication_code: state?.authenticationCode,
        }
        putUrl(`/user/crypto-address`, params).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                setState({ cryptoAddress: "", walletType: "", securityPassword: "", authenticationCode: "" });
                addAlert(t('success.editSuccess'), 'success');
            } else {
                setInputErrors(response.errors);
                let message = '';

                if (response.errors) {
                    if (response.errors.crypto_address || response.errors.security_password) {
                        message = t('error.editError');
                    } else {
                        message = response.errors.authentication_code;
                    }
                } else {
                    message = response.message;
                }
                addAlert(message, 'error');
            }
        }).catch(error => {
            setLoading(false);
            addAlert(error.message || t('error.contactSupport'), 'error');
        });
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" spendButton />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'><b>{t('profile.cryptoAddress')}</b></p>
                    <p className='fs-subheader cl-label'>{t('profile.cryptoDesc')}</p>
                </div>
            </div>

            <Container>
                <div>
                    <div className='p-t-25'>
                        <p className='fs-textfield'>{t('profile.newCryptoAddress')}</p>
                        <TextField
                            variant="filled"
                            fullWidth
                            size="small"
                            name="cryptoAddress"
                            value={state.cryptoAddress || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                            error={inputErrors && inputErrors.crypto_address ? true : false}
                            InputLabelProps={{ shrink: true }}
                        />
                        {
                            wallets?.crypto?.crypto_address &&
                            <div className='p-t-10 theme-txt fs-13' style={{ fontWeight: 500 }}>
                                <p>{t('profile.currentCryptoAddress')} {wallets?.crypto?.crypto_address}</p>
                            </div>
                        }
                        <div className='theme-txt fs-13' style={{ fontWeight: 500 }}>
                            <p>{t('profile.supportedCryptoAddress')}</p>
                        </div>
                    </div>

                    <div className='p-t-25'>
                        <p className='fs-textfield'>{t('profile.currentSecurityPassword')}</p>
                        <TextField
                            variant="filled"
                            fullWidth
                            size="small"
                            name="securityPassword"
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            value={state.securityPassword || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                        >
                                            {showPassword.securityPassword ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={styles.textFieldBox}
                        />
                    </div>

                    <div className='p-t-25'>
                        {/* <TextField
                            variant="standard"
                            fullWidth
                            name="walletType"
                            placeholder={t('profile.walletType')}
                            value={state.walletType || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.wallet_type ? inputErrors.wallet_type : ''}
                            error={inputErrors && inputErrors.wallet_type ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiKey />
                                    </InputAdornment>
                                ),
                            }}
                            className={styles.textFieldBox}
                        /> */}
                    </div>

                    <div className='w-full p-t-50'>
                        <div className='btn-theme w-full translateY' onClick={updateCryptoAddress}>
                            <p className='fs-button'>{t('button.update')}</p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const useStyles = makeStyles(theme => ({

    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
}));

export default ProfileCryptoAddress
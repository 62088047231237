import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

//MUI
import { Link } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

//ICON
import { FiChevronRight } from "react-icons/fi";



const AnnouncementCard = ({ announcement }) => {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const getTitle = () => {
        const lang = i18n.language;
        return announcement[`title_${lang}`] || announcement.title_en;
    }

    const formattedDate = announcement.updated_at ? moment(announcement.updated_at).format('YYYY-MM-DD') : '';

    return (
        <Link component={RouterLink} underline="none" to={`/announcement-detail/${announcement.id}`} >
            <div className="glass2 shadow-glass2 bor15 p-all-15" style={{ marginRight: theme.spacing(2) }}>
                <div>
                    <div>
                        <img src={announcement.image.file_name} alt={getTitle()} className={styles.announcementImage} />
                    </div>
                    <div>
                        <p className="fs-content txt_truncate p-t-10"><b>{getTitle()}</b></p>
                    </div>
                </div>
                <div className='flex-sb-m p-t-5 w-full'>
                    <p className='fs-remark'>{formattedDate}</p>
                        <FiChevronRight className='fs-icon-small cl-theme' />
                </div>
            </div>
        </Link>
    );
}

AnnouncementCard.propTypes = {
    announcement: PropTypes.object.isRequired,
    // unreadCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
    announcementImage: {
        width: '100%',
        height: 120,
        display: 'block',
        borderRadius: 10,
        objectFit: 'cover',
    },
}));

export default AnnouncementCard;
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// import { loginUser } from '@helper/ApiAction';
// import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// import { Link as RouterLink, useHistory } from 'react-router-dom';
// import { authSuccess, authFail, updateShareLinkRef, changeLanguage as reduxChangeLanguage } from '@actions';
import { postUrl, getUrl } from '@helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '@helper/Tools';

// import { BACKEND_URL, CLIENT_SECRET } from '@configs/Config';
// import axios from 'axios';
// import jwt_decode from "jwt-decode";

//MUI
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField, Button, Grid, Typography, MenuItem, FormControlLabel, Checkbox, FormHelperText, } from '@material-ui/core';

//ICON
// import { CheckCircle } from '@material-ui/icons';
// import { FaCheck } from "react-icons/fa6";

const INITIAL_ACC_TYPE = 'newAcc';

function useInput({ type, label, val, helperText, disabled = false }) {
    const [value, setValue] = useState(val ? val : "");
    const [error, setErrorValue] = useState("");
    const input =
        <div className='p-b-20'>
            <TextField fullWidth label={label} error={error ? true : false} helperText={error} variant="filled" value={value} onChange={e => setValue(e.target.value)} type={type} InputProps={{ readOnly: disabled }} />
            {helperText && <FormHelperText className='cl-label'>{helperText}</FormHelperText>}
        </div>;
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val, disabled = false }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <div className='p-b-20'>
            <TextField
                select
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="filled"
                fullWidth
                error={error ? true : false}
                helperText={error}
                disabled={disabled}
            >
                {data.map((option, key) => (
                    <MenuItem key={key} value={option.value} style={{ color: '#222' }}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    return [value, select, setErrorValue, setValue];
}

function useCheckbox({ label, val, note = '' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <div className='p-b-20'>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" >{note}</Typography>
        </div>;
    return [value, checkbox, setErrorValue];
}

const RegisterForm = (props) => {
    const { data, refId , setErrorMessage , setSuccessMessage, setData } = props;
    const query = useQuery();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const placementPosition = [{ value: 0, label: t("register.placementPosition.no") }, { value: 1, label: t("register.placementPosition.left") }, { value: 2, label: t("register.placementPosition.right") }];
    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name'), val: data?.name });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email'), val: data?.email });
    // const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile') });
    const [nric, nricInput, nricValidate] = useInput({ type: "text", label: t('register.nric_passport'), val: data?.nric });
    const [usernameField, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username'), val: data?.username });
    const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral') });
    const [placement, placementInput, placementValidate] = useInput({ type: "text", label: t('register.placement'), val: data?.placement });
    const [position, positionInput, positionValidate] = useSelect({ data: placementPosition, label: t('register.position'), val: data?.position });
    const [autoPlacement, autoPlacementInput, autoPlacementValidate] = useCheckbox({ label: t('register.autoPlacement'), val: data?.autoPlacement });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password'), helperText: t('profile.passwordValidation'), val: data?.password });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation'), val: data?.passwordConfirmation });

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: query.get("country") ? query.get("country") : "",
        code: "",
        mobile: "",
        error: {},
    })

    const validate = { name: nameValidate, email: emailValidate, nric: nricValidate, password: passwordValidate, password_confirmation: passwordConfirmationValidate, username: usernameValidate, placement: placementValidate, position: positionValidate, autoPlacement: autoPlacementValidate, referral: referralValidate };

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true)
        getUrl(`mobile-code-list`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setMobileCodeList({ ...mobileCodeList, country: response?.data?.country_list });
                }
            }

        }).catch(error => {
            // addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
    }, []);

    useEffect(() => {

        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList({ ...mobileCodeList, code: codeList });
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, mobileCountry: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setUserMobile({ ...userMobile, [name]: value });
                break;
            default:
                setUserMobile({ ...userMobile, [name]: value });
        }
    };

    const handleSubmit = (evt) => {
        let postData = {};
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })

        postData = {
            name,
            email,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
            nric,
            username: usernameField,
            placement: autoPlacement ? '' : placement,
            position: autoPlacement ? null : (position > 0 ? position : null),
            auto_placement: autoPlacement ? 1 : 0,
            password,
            password_confirmation: passwordConfirmation
        }

        if (refId) {
            postData.ref_id = refId;
        } else {
            postData.referral = referral;
        }

        postUrl(`register`, postData)
            .then((response) => {
                // console.log(response);
                let { status, message, error } = response;
                if (status === 0) {
                    setLoading(false);
                    setErrorMessage(message);
                    let err_arr = {};
                    _.map(error, (value, key) => {

                        if (_.includes(['mobile', 'country', 'mobile_code'], key)) {
                            err_arr[key] = value[0];
                        } else {
                            validate[key](value[0]);
                        }
                    })
                    setUserMobile({ ...userMobile, error: err_arr });
                } else {
                    setLoading(false);
                    setSuccessMessage(message);
                    setData(postData);

                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {nameInput}
                {emailInput}
                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <TextField
                        select
                        label={t('register.country')}
                        value={userMobile.country}
                        onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                        variant="filled"
                        fullWidth
                        error={userMobile?.error?.country ? true : false}
                        helperText={userMobile?.error?.country}
                    >
                        {
                            _.size(mobileCodeList?.country) > 0
                                ?
                                _.map(mobileCodeList?.country, (option, key) => {
                                    return (
                                        <MenuItem key={key} value={option.code} style={{ color: '#222' }}>
                                            {i18n.language === 'cn' ? option.name_cn : option?.name}
                                        </MenuItem>
                                    )
                                })
                                :
                                <MenuItem value={''} style={{ color: '#222' }}>
                                    {t('general.pleaseSelect')}
                                </MenuItem>
                        }
                    </TextField>
                </Grid>
                <div className='flex-sb-m w-full p-t-10 p-b-20' style={{ flexWrap: 'wrap' }}>
                    <div style={{ width: 'calc(30% - 20px)' }}>
                        <TextField
                            select
                            label={t('register.code')}
                            value={userMobile.code}
                            onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                            variant="filled"
                            fullWidth
                            error={userMobile?.error?.mobile_code ? true : false}
                            disabled = { _.size(mobileCodeList?.code) > 0 ? false:true}
                        >
                            {
                                _.size(mobileCodeList?.code) > 0 ?
                                 _.map(mobileCodeList?.code, (option, key) => {
                                    return (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    )
                                })
                                :<MenuItem value="" style={{ color: '#222' }} >{``}</MenuItem>
                            }
                        </TextField>
                    </div>
                    <div className='txt-center' style={{ width: 40 }}>
                        <p className='fs-21'>-</p>
                    </div>
                    <div style={{ width: 'calc(70% - 20px)' }}>
                        <TextField
                            label={t('register.mobile')}
                            value={userMobile.mobile}
                            onChange={handleChange}
                            name="mobile"
                            variant="filled"
                            fullWidth
                            error={userMobile?.error?.mobile ? true : false}
                        />
                    </div>
                    {
                        userMobile?.code > 0 ?  <></>: <FormHelperText className="p-l-10">{t('general.pleaseSelectCountry')}</FormHelperText>
                    }
                    {
                        (userMobile?.error?.mobile_code || userMobile?.error?.mobile) && <FormHelperText style={{ color: 'red' }} className="p-l-10" >{_.join([userMobile?.error?.mobile_code, userMobile?.error?.mobile], ' ')}</FormHelperText>
                    }
                </div>
                {nricInput}
                {usernameInput}
                {!refId ? referralInput
                    :
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <TextField fullWidth label={t('register.referral')} variant="filled" value={data.referral} type="text" InputProps={{ readOnly: true }} />
                    </div>
                }
                {/* {!data?.placement && !data?.position &&autoPlacementInput} */}
                {
                    autoPlacement ? null :
                        <>
                            {placementInput}
                            {positionInput}
                        </>
                }
                {passwordInput}
                {passwordConfirmationInput}
                <div>
                    <Button type="submit" fullWidth variant="contained" color="primary" size="large">{t('button.register')}</Button>
                </div>
            </form>
        </div>
    )
}

export default RegisterForm;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { Typography, Container } from '@material-ui/core';
import { useQuery } from '@helper/Tools';

import TitleBar from '@layouts/TitleBar';
import RegisterForm from '@layouts/RegisterForm';

import '@css/styles.css';

import { FaCheck } from "react-icons/fa6";

export default function Recruit() {

    const { username } = useSelector(state => state.user);
    const { t } = useTranslation();
    const query = useQuery();
    const queryPlacement = query.get('placement') ? query.get('placement') : "";
    const queryPosition = query.get('position') ? query.get('position') : 0;
    const [data, setData] =useState({
        referral: username,
        placement:  queryPlacement,
        position: queryPosition,
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    return (
        <div>
            <TitleBar back backgroundColor="bg-theme" spendButton />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('profile.recruit')}</p>
                    <p className='fs-subheader'>{t('profile.recruitSubtitle')}</p>
                </div>
            </div>
            <Container>

                {
                    successMessage ?
                        <div className='glass2 shadow-glass2 bor15 p-all-15 m-t-25'>
                        <div className='bor50 p-all-5' style={{ width: 40, height: 40, background: '#169987a8', margin: '0 auto' }}>
                            <div className='w-full h-full bor50 flex-c-m' style={{ background: '#169987a8' }}>
                                <FaCheck className='fs-21 clwhite' />
                            </div>
                        </div>
                        <div className='p-tb-25'>
                            <p className='txt-center cl-label fs-15'>{t('register.recruitSuccessMsg')}</p>
                        </div>
                    </div>
                        :
                        <div className='p-t-25' >
                            <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                            <RegisterForm setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} data={data} setData={setData} />
                        </div>
                }
            </Container>
        </div>
    )
}
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams , useHistory} from 'react-router-dom';
import _ from 'lodash';

import { Button, Container} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { QRCodeSVG } from 'qrcode.react';
import 'react-awesome-slider/dist/styles.css';
import Slider from "react-slick";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
// import CustomSelect from '@components/CustomSelect';

const TOKENS = ['USDT', 'KEY', 'CARD', 'NFT'];
const NETWORK = ['ERC20', 'KEY', 'CARD', 'NFT'];

const INITIAL_STATE = {
    bsc: [],
    trx: [],
    wallet: '',
    details: []
    // tokens: "",
    // network: "",
    // depositAddress: '0x0000000000000000000000000000000000000000',
}

const Deposit = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const history = useHistory();
    let sliderRef = useRef(null);

    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState(INITIAL_STATE);
    const [activeTab, setActiveTab] = useState('trx');
    const [currentPage, setCurrentPages] = useState(0);

    const slides = [
        { key: 'trx', label: t('wallet.trx') },
        { key: 'bsc', label: t('wallet.bsc') }
    ];

    // const [qrcode, setQrcode] = useState('');
    
    // const handleChange = ({ target }) => {
    //     let { name, value } = target;
    //     setState({ ...state, [name]: value });
    // };

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        if (!existWallet || existWallet.allowDeposit === false) {
            history.goBack();
        } else {
            getDepositAddress(existWallet?.id);
        }
        
        // eslint-disable-next-line
    }, [wallet, walletBalance]);

    const handleAfterChange = (current) => {
        setActiveTab(slides?.[current]?.key);
    };

    const getDepositAddress = (value) => {
        setLoading(true);

        try {
            let apiData = {
                wallet: parseInt(value),
            };
            
            getUrl('user/get-deposit-address', apiData).then(response => {
                if (response.status) 
                {
                    setState({ ...state, trx: response.trx , bsc: response.bsc , details: response.details});
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            })
        }
        catch (error) {
            addAlert(JSON.stringify(error.message));
        }
    }

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert(t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert(t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert(t('general.copied'), "success");
            }).catch((error) => {
                addAlert(t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert("Copy is not supported by browser", "error");
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div>
                <TitleBar backgroundColor="bg-theme" back /*currencyButton*/ spendButton />
                {/* <Typography style={{ fontSize: 12, padding: '15px 40px 0 ', width: '100%'}}>
                    {t('deposit.noti', {'value': state?.details?.min_amount})}
                </Typography> */}
                <div className={`bg-theme p-b-200 header-radius`}></div>

                <Container>
                    <div style={{ marginTop: -200 }}>
                        <Slider
                            ref={slider => {
                                sliderRef = slider;
                            }}
                            infinite={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            initialSlide={currentPage}
                            responsive={[
                                { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                                { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                                { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                            ]}
                            afterChange={handleAfterChange}
                        >
                            {
                                _.map(slides, (detail, idx) => (
                                    <div key={idx}>
                                        <div key={detail.key} className='p-lr-15 p-tb-20'>
                                            <p className='txt-center clwhite txt-upper fs-15 p-b-20'>{detail?.label} {t('title.deposit')}</p>
                                        </div>

                                        {
                                            state.trx && state.bsc &&
                                            <div className='p-b-10'>
                                                <div className='pos-relative' style={{ zIndex: 2 }}>
                                                    {
                                                        activeTab &&
                                                        (
                                                            <div style={{ display: 'grid', placeItems: 'center' }}>
                                                                <div className='glass2 p-tb-20 p-lr-20 flex-c-m' style={{ borderRadius: 21, width: 350, maxWidth: '85%', margin: '0 auto' }}>
                                                                    <QRCodeSVG
                                                                        bgColor="transparent"
                                                                        fgColor="#000"
                                                                        value={state[activeTab]?.address}
                                                                        size={150}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p className='p-lr-5 p-tb-15 txt-center fs-14' style={{ wordBreak: 'break-all' }}>{state[activeTab]?.address}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </Container>

                <Container>
                    {
                        state.trx && state.bsc && activeTab &&
                        (
                            <div className='p-lr-20'>
                                <Button
                                    variant="contained"
                                    className='big-button'
                                    fullWidth
                                    onClick={() => copyShareLink(state[activeTab]?.address)}
                                >
                                    {t('button.copyAddress')}
                                </Button>
                                <div className='txt-caption p-t-10'>
                                    <p className='txt-center'>
                                        {t('deposit.notiwarrning')} {t('deposit.depositFee', { 'min_amount': state?.details?.value?.deposit_fee?.top_up_minimum_amount, 'deposit_fee': state?.details?.value?.deposit_fee?.type == 'fixed' ? state?.details?.value?.deposit_fee?.amount : state?.details?.value?.deposit_fee?.amount + "%" })}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </Container>

                {/* {
                    state.trx && state.bsc &&
                    (
                        <>
                            <div style={{ width: 550, margin: ' 0 auto', zIndex: 2 }} className='max-w-full pos-relative'>
                                <Tabs value={activeTab} onChange={handleTabChange} style={{ overflow: 'unset', }}>
                                    <Tab label={t('wallet.trx')} value="trx" />
                                    <Tab label={t('wallet.bsc')} value="bsc" />
                                </Tabs>
                            </div>

                            <div className='dashboard-w' style={{ borderTopLeftRadius: 0, paddingTop: 40 }} >
                                <div className='pos-relative ' style={{ zIndex: 2 }}>
                                    <div className='txt-center w-full p-b-30 p-t-20'>
                                        <Typography variant='h6' className='text-gold-short' style={{ fontWeight: 'bold', lineHeight: 1.1 }} >{state[activeTab] === state.trx ? t('wallet.trx') : t('wallet.bsc')}  {t('title.deposit')}</Typography>
                                    </div>
                                    <div className='pos-relative' style={{ zIndex: 2 }}>
                                        {
                                            activeTab &&
                                            (
                                                <div style={{ display: 'grid', placeItems: 'center' }}>
                                                    <div>
                                                        <QRCodeSVG
                                                            bgColor="transparent"
                                                            fgColor="#eed189"
                                                            value={state[activeTab]?.address}
                                                            size={150}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className='p-lr-5 p-tb-15 txt-center  fs-14' style={{ wordBreak: 'break-all' }}>
                                                            {
                                                                state[activeTab]?.address
                                                            }
                                                        </p>
                                                    </div>

                                                    <Button
                                                        variant="contained"
                                                        className='big-button'
                                                        fullWidth
                                                        onClick={() => copyShareLink(state[activeTab]?.address)}
                                                    >
                                                        {t('button.copyAddress')}
                                                    </Button>
                                                    <div className='txt-caption'>
                                                        <p>
                                                            {t('deposit.notiwarrning')} {t('deposit.depositFee', { 'min_amount': state?.details?.deposit_fee?.top_up_minimum_amount, 'deposit_fee': state?.details?.deposit_fee?.type == 'fixed' ? state?.details?.deposit_fee?.amount : state?.details?.deposit_fee?.amount + "%" })}
                                                        </p>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                } */}
            </div>
        </>
    )
   
}

const useStyles = makeStyles(theme => ({
    // depositBox: {
    //     borderRadius: 6,
    //     backgroundColor: '#D6A364',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     marginTop: 20,
    //     paddingLeft: 5,
    //     paddingRgiht: 5,
    //     justifyContent: 'center'
    // },
    // optionWalletBonusLayout: {
    //     borderRadius: '48px',
    //     background: '#E7E7E7',
    //     boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
    // },
    // tabButton: {
    //     borderRadius: '48px',
    //     cursor: 'pointer',
    //     transition: 'background-color 0.3s',
    //     color: '#949494',
    // },
    // activeTab: {
    //     color: 'white',
    //     background: 'linear-gradient(91deg, #86552E 2.67%, #DDA253 99.35%)',
    //     boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
    // },
}));


export default Deposit;
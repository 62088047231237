import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';


//MUI
// import { makeStyles } from '@material-ui/core/styles';
import { Container,  Link } from '@material-ui/core';

//ICON
import { FiChevronRight, FiShoppingBag } from "react-icons/fi";
import { LuGift } from "react-icons/lu";
import { FaRegStar } from "react-icons/fa";


//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


export default function MyOrder() {
    const { t } = useTranslation();

    const { accessToken} = useSelector(state => state.general);
    // const theme = useTheme();
    // const styles = useStyles();




    return (

        <div>
            <TitleBar back btnRegister backUrl="/settings" spendButton />

            <Container>
                <div className='p-b-20'>
                    <p className='fs-16 cl-theme'><b>{t('title.order')}</b></p>
                </div>

                <div>

                    <Link underline='none' to="/order" component={RouterLink} className='pointer'>
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiShoppingBag className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('title.order')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>
                    <div className='underline-white w-full' style={{ height: 4 }}></div>

                    <Link underline='none' to="/my-package" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <LuGift className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('title.myPackage')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/my-wishlists" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FaRegStar className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('title.myWishlists')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                </div>
            </Container>
        </div>
    );
}


// const useStyles = makeStyles(theme => ({



// }));



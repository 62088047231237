import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';
import { postUrl } from '@helper/ApiAction';
import { APP_NAME } from '@configs/Config';
import useNotificationLoading from '@helper/useNotificationLoading';
import { useQuery } from '@helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Container } from '@material-ui/core';
import { FaCheck } from "react-icons/fa";



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordReset() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let { token } = useParams();
    const query = useQuery();
    const email = query.get("email");

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({ password: '', passwordConfirm: '' });
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    /* useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]); */

    const resetPassword = () => {
        setLoading(true);
        const apiData = {
            password: state.password,
            password_confirmation: state.passwordConfirm,
            token,
            email
        }
        // console.log("apiData", apiData);
        postUrl(`reset_password`, apiData).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 1) {
                    addAlert(message, 'success', '', '');
                    setCompleted(true);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n" + value[0];
                        })
                        setInputErrors(error);
                    }
                    addAlert(message, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
            <Container>
                <div className='p-t-40 p-b-20'>
                <Link color="inherit" href="/login" >
                    <div style={{ width: 120, margin: '0 auto' }}>
                        <img src="/images/logo/logo-fit.png" className='w-full' alt="logo" />
                    </div>
                </Link>
              
                </div>
                {/* <Divider variant="middle" style={{ width: '100%', }} /> */}
                    {!completed ?
                        <>
                        <p className='fs-header txt-center p-t-40 p-b-5'>
                            {t('general.resetYourPassword')}
                        </p>
                            <p className='fs-content txt-center p-b-10'>{t('general.enterNewPassword')} - {email}.</p>
                            <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); resetPassword() }}>
                                <TextField
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="New Password"
                                    name="password"
                                    value={state.password}
                                    onChange={({ target }) => setState({ ...state, password: target.value })}
                                    autoFocus
                                    type="password"
                                    error={inputErrors.password ? true : false}
                                    helperText={inputErrors.password ? inputErrors.password : ''}
                                />
                                <TextField
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="passwordConfirm"
                                    label="Re-enter New Password"
                                    name="passwordConfirm"
                                    value={state.passwordConfirm}
                                    onChange={({ target }) => setState({ ...state, passwordConfirm: target.value })}                                    
                                    type="password"
                                    error={inputErrors.password_confirmation ? true : false}
                                    helperText={inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                />
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    style={{width: 194, padding:'8px 9px', maxWidth: '80%', borderRadius: 48, }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >{t('button.changePassword')}</Button>
                                </div>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        
                        </>
                        :
                        <>
                        <div className='txt-center p-t-60' style={{ color: '#21b38b' }}>
                            <FaCheck className='fs-40' />
                        </div>
                        <p className='fs-header txt-center p-t-15' style={{ color: '#21b38b' }}>
                            {t('general.resetSuccessfully')}
                        </p>
                        <p className='fs-content p-t-25 txt-center'>{t('general.resetPasswordSuccess')}</p>
                        <Link underline='none' to={`/login`} component={RouterLink}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('button.proceedToLogin')}
                            </Button>
                        </Link>
                        </>
                    }
                </Container>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        overflowY:'auto'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));
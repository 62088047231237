import { useLocation } from "react-router-dom";
import { useRef, useEffect } from 'react';
import { store } from '@configs/Store';
import _ from "lodash";

export const generateRandomString = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characterLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characterLength));
    }
    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const formatAmount = amount => {
    let formatAmount = '';

	const amountString = amount.toString().replace(/\./g, '');
    const totalPad = 12 - amountString.length;
    
    let newAmount = "";
    for (var i = 0; i < totalPad; i++) {
        newAmount += "0";
    }
    
    formatAmount = newAmount + amountString;
    return formatAmount;
}

export const currencyFormat = (amount, decimal = 2) => {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal, roundingMode: "trunc" }).format(amount); 
}

export const convertToK = (amount) => {
    return new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumSignificantDigits: 3
    }).format(amount);
}

export const stringToLang = (string) => {
    if (string === "") return "";
    const language = store.getState().general.language;
    let key_lang = { en: 0, cn: 1 };
    let stringArray = _.split(string, '|');
    return stringArray[key_lang[language]] ? stringArray[key_lang[language]] : stringArray[key_lang['en']];
}

import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getUrl, postUrl, deleteUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//MUI   
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container,  Link , Grid , Button } from '@material-ui/core';

//ICON
// import { FiChevronRight, FiShoppingBag } from "react-icons/fi";
// import { LuGift } from "react-icons/lu";


//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import ProductItemCard from '@components/ProductItemCard';

export default function MyWishlists() {
    const { t } = useTranslation();
    const styles = useStyles();

    const { accessToken , currencyDisplay , currency } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const [recommend, setRecommend] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    let currency_code = [];
    _.map(currencyDisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    useEffect(() => {
        let apiData = {
            'page': page, 
            'per_page':  6, 
        };

        getUrl(`get_my_wishlists`, apiData).then(response => {
            if (response.status) {
                setTotalPage(response.products.last_page);
                setRecommend(_.concat(recommend, response.data));
            }
        }).catch((error) => {
            addAlert(JSON.stringify(error.message));
        });

        // eslint-disable-next-line
    }, [page]);

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const productList = () => {
        return (
            <>
                {
                    _.size(recommend) > 0
                        ?
                        <>
                            <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                                {
                                    _.map(recommend, (product, key) => {
                                        return (
                                            <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                                <ProductItemCard product={product} currencyCode={currency_code} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <div className='p-tb-15'>
                                {
                                    page < totalPage
                                        ? <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                                        : <p className='txt-center fs-content'>{t('transaction.endOfList')}</p>
                                
                                }
                            </div>
                        </>
                        : 
                        <div>
                            <div className='max-w-full' style={{ width: 150, margin: '0 auto' }}>
                                <img src="/images/empty/favourite.png" alt="favourite" />
                            </div>
                            <p className='fs-title txt-center cl-label' style={{fontWeight:'normal'}}>{t('wishlist.empty')}</p>
                            <Link underline='none' to={`/`} className='flex-c-m p-t-10 translateY' component={RouterLink} style={{ width: 250, margin: '0 auto', maxWidth: '100%' }}>
                                <div className='btn-theme'>
                                    <p className='fs-button'>{t('wishlist.goShopping')}</p>
                                </div>
                            </Link>
                        </div>
                }
            </>
        )
    }

    return (

        <div>
            <TitleBar back displayCart backgroundColor="bg-theme" spendButton />

            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('wishlist.title')}</p>
                    {productList.length > 0 ?
                        <p className='fs-subheader cl-label'>{t('wishlist.subtitle')}</p>
                        :
                        <p className='fs-subheader cl-label'>{t('wishlist.empty')}</p>
                    }
                </div>
            </div>

            <Container>
                {productList()}
                
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        maxWidth: '100%'
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
}));
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Slide, useScrollTrigger } from '@material-ui/core';

import { changeLanguage as reduxChangeLanguage, changeCurrencyRate, removeUuid, storeProfile, storeUuid, storeWallets, updateCartTotal } from '@actions';
import { API } from '@configs/AxiosConfig';
import { DEFAULT_LANGUAGE } from '@configs/Config';
import { getUrl, postUrl, removeLoginAccess } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function Header(props) {
    const { uuid, accessToken } = useSelector(state => state.general);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();

    useEffect(() => {
        if (_.includes(['en', 'cn'], i18n.language) === false) {
            changeLanguage(DEFAULT_LANGUAGE || 'en');
        }
        API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
        // eslint-disable-next-line
    }, [i18n.language, t]);

    useEffect(() => {
        if (accessToken) {
            API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            if (API.defaults.headers.common['Guest-Token']) {
                delete API.defaults.headers.common['Guest-Token'];
                postUrl('carts/migrate', {
                    guest_token: uuid
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
            getUrl('user')
                .then(userData => {
                    if (uuid) {
                        dispatch(removeUuid());
                    }
                    if (userData.status === 1) {
                        dispatch(storeProfile(userData.data));
                    }
                }).catch(error => {
                    removeLoginAccess();
                });
        } else {
            delete API.defaults.headers.common['Authorization'];
            API.defaults.headers.common['Guest-Token'] = uuid;
        }

        getUrl('carts')
            .then(cartList => {
                let cartTotal = 0;
                _.map(cartList.data, cartItem => {
                    _.map(cartItem.items, cartDetails => {
                        cartTotal += parseInt(_.size(cartDetails));
                    })
                })
                dispatch(updateCartTotal(cartTotal));
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        if (!uuid && !accessToken) {
            const createdUuid = uuidv4();
            dispatch(storeUuid(createdUuid));
        }

    }, [uuid, accessToken, dispatch, addAlert]);

    useEffect(() => {
        getUrl('wallets').then(response => {
            if (response.status) {
                dispatch(storeWallets(response.data));
            }
        }).catch(error => {
            console.log("wallets error", error);
        })
        getUrl(`get_currentcy_rate`).then(result => {
            result['data'] = JSON.parse(result['data']);
            dispatch(changeCurrencyRate(result));
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, [accessToken])

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return null;
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNotificationLoading from '@helper/useNotificationLoading';
import _ from 'lodash';

import { changeLanguage as reduxChangeLanguage, changeCurrency as reduxChangeCurrency } from '@actions';
import { getUrl, logoutUser } from '@helper/ApiAction';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {  createMuiTheme, ThemeProvider, FormControlLabel, Container, Drawer, FormControl, Radio, RadioGroup, Typography, Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';

//ICON
import { PublicOutlined, ExpandMoreOutlined, CheckCircle} from '@material-ui/icons';
import { FiChevronRight } from 'react-icons/fi';
import { BiDollar } from "react-icons/bi";
import { IoLanguageSharp } from "react-icons/io5";
import { MdCurrencyExchange } from "react-icons/md";






//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import theme from '../../Theme';


export default function Setting() {
    const { t, i18n } = useTranslation();
    const [valueLanguage, setLanguage] = useState(i18n.language);

    const { accessToken, role, currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const [currency2, setcurrency2] = useState(currency);
    const { fr_id, max_rank, username, id, name, email, mobile, nric, referral_username, max_rank: rank, max_mrank, } = useSelector(state => state.user);
    const [unreadNotification, setunreadNotification] = useState();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };
    const changeCurrency = (curr) => {
        setcurrency2(curr);
        dispatch(reduxChangeCurrency(curr));
    };

    useEffect(() => {
        getUrl(`notifications`).then(response => {
            if (response.status) {
                setunreadNotification(response.new_count);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

    }, []);

    //Drawer
    const [state, setState] = React.useState({
        languageDrawer: false,
        currecncyDrawer: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    return (

        <div>
            <TitleBar  back btnRegister languageButton={false} backUrl="/settings"  spendButton />
            <Container>
                <div className='p-b-20'>
                    <p className='fs-title cl-theme'><b>{t('profile.settings')}</b></p>
                </div>

                <div className='flex-sb-m w-full p-all-15 pointer' onClick={toggleDrawer('languageDrawer', true)}>
                    <div className='flex-m cl-theme'>
                        <IoLanguageSharp className='fs-icon-small' />
                        <p className='p-l-15 fs-content'>{t('home.language')}</p>
                    </div>
                    <div className='flex-m'>
                    <p className='p-r-15 txt-upper fs-content cl-theme'>{valueLanguage=== 'cn' ? "中文" : "English"}</p>
                    <FiChevronRight className='fs-content cl-theme' />
                    </div>
                </div>

                {/* <div className='underline-white w-full' style={{ height: 4 }}></div>

                <div className='flex-sb-m w-full p-all-15 pointer' onClick={toggleDrawer('currecncyDrawer', true)}>
                    <div className='flex-m cl-theme'>
                        <MdCurrencyExchange className='fs-icon-small' />
                        <p className='p-l-15 fs-content'>{t('home.currency')}</p>
                    </div>
                    <div className='flex-m'>
                    <p className='p-r-15 fs-content cl-theme'>{currency}</p>
                    <FiChevronRight className='fs-content cl-theme' />
                    </div>
                </div> */}
            </Container>

            <Drawer anchor="bottom" open={state.languageDrawer} onClose={toggleDrawer('languageDrawer', false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer('languageDrawer', false)}
                    onKeyDown={toggleDrawer('languageDrawer', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 cl-label'>{t('settings.selectLanguage')}</p>
                    
                    <div className='pointer p-tb-15 w-full flex-sb-m' onClick={() => changeLanguage('en')}>
                        <p>English</p>
                        <FiChevronRight />
                    </div>
                    <div className='divider-white p-tb-2' />
                    <div className='pointer p-tb-15 w-full flex-sb-m' onClick={() => changeLanguage('cn')}>
                        <p>中文</p>
                        <FiChevronRight />
                    </div>
                </div>
            </Drawer>

            {/* <Drawer anchor="bottom" open={state.currecncyDrawer} onClose={toggleDrawer('currecncyDrawer', false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer('currecncyDrawer', false)}
                    onKeyDown={toggleDrawer('currecncyDrawer', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 cl-label'>{t('settings.selectCurrency')} </p>
                    {_.map(currencydisplay, (currencydisplay_detail, index) => {
                        let currency_code = currencydisplay_detail.desc.split('|');
                        return (
                            <div key={index}>
                                <div className='pointer p-tb-15 w-full flex-sb-m' onClick={() => changeCurrency(`${currencydisplay_detail['code']}`)}>
                                    <p>{`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `}</p>
                                </div>
                                <div className='divider-white p-tb-2' />
                        </div>
                        )
                    })}
                </div>
            </Drawer> */}


            {/* //Setting */}
            {/* <ThemeProvider theme={accordingStyles}>
                <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreOutlined />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                <BiDollar fontSize="default" />
                            </Avatar>
                            <Typography style={{ color: theme.palette.silver.text }}>{t('home.currency')}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '8px 55px 16px', }}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="currency" name="currency" value={currency}>
                                {_.map(currencydisplay, (currencydisplay_detail, index) => {
                                    let currency_code = currencydisplay_detail.desc.split('|');
                                    return (<FormControlLabel
                                        onChange={() => changeCurrency(`${currencydisplay_detail['code']}`)}
                                        value={currencydisplay_detail['code']}
                                        control={<Radio color="primary" checkedIcon={<CheckCircle color="secondary" />} />}
                                        label={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `}
                                    />)
                                })}
                            </RadioGroup>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
            </ThemeProvider> */}
            </div>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',

    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "transparent"
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        backgroundColor: '#c9c9c9',
        color: "#fff"
    },
    unreadiconColor: {
        padding: 5,
        width: 20,
        height: 20,
        backgroundColor: '#ff0000',
        color: "#fff"
    },
}));


const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent'
                }

            }
        },
        MuiAccordionSummary: {
            content: {
                marginTop: 0,
                "&$expanded": {
                    marginTop: 0,
                    marginBottom: 5,
                }
            },
            root: {
                "&$expanded": {
                    minHeight: 'unset',
                }
            },
        },
        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            }

        },
    }
});


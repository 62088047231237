import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUrl, postUrl, putUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { ArtTrackOutlined, AspectRatioOutlined, CloseOutlined } from '@material-ui/icons';
import { Edit, Plus } from 'react-feather';

const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "", mobile_code: " " };

export default function CheckoutAddressCard(props) {
    const { state, setState, addresses, setAddress, fetchingMallShipping, mallShippingRefresh, setMallShippingRefresh } = props;
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();
    const { addAlert } = useNotificationLoading();

    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [addressMobileCode, setAddressMobileCode] = useState({});
    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile_code: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // GET ADDRESS LIST
    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('user/address')
                .then(addressList => {
                    if (isMountedRef.current) {
                        setAddress(addressList.data);
                        let defaultAddress = _.find(addressList.data, { 'default': true });
                        if (_.size(defaultAddress) > 0) {
                            setState(state => ({ ...state, addressId: defaultAddress.id }))
                        } else {
                            if (_.size(addressList.data)) {
                                const firstAddress = addressList.data[0];
                                setState(state => ({ ...state, addressId: firstAddress.id }))
                            }
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    // GET ADDRESS GROUP
    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [addAlert, t]);

    // SET ADDRESS MOBILE CODE
    useEffect(() => {
        if (_.size(countries) > 0) {
            if (addressFormState?.country !== '') {
                let codeList = {};
                let countryCode = addressFormState?.mobile_code;
                let code = _.find(countries, { 'code': addressFormState?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (addressFormState?.mobile_code !== '' ? addressFormState?.mobile_code : '');
                    }
                }

                setAddressMobileCode(codeList);
                setAddressFormState(prevState => ({ ...prevState, mobile_code: countryCode }));
            } else if (addressFormState?.country === '' && addressFormState?.mobile_code !== '') {
                let tempArr = _.clone(countries);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, addressFormState?.mobile_code));
                if (_.size(tempArr) > 0) {
                    setAddressMobileCode(tempArr[0]['tel_code']);
                    setAddressFormState(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [countries, addressFormState?.country, addressFormState?.mobile_code]);

    // SET UPLOADIC BY COUNTRY
    useEffect(() => {
        if (addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    // GET COUNTRY
    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            if (isMountedRef.current) {
                setCountry(countryList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [i18n.language, addAlert, t]);

    // GET STATES BY COUNTRY
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    // GET CITIES BY STATE
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country, addressFormState.state]);

    // GET DISTRICTS BY CITY
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country, addressFormState.city]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'icFront') {
            if (icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if (name === 'icBack') {
            if (icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        setAddressFormState({ ...addressFormState, [name]: value });
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };

    const openAddAddress = () => {
        setErrorAddressState({});
        setState(state => ({ ...state, mode: 'add', modalShow: true }));
    };

    const openEditAddress = selectedAddressId => {
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': selectedAddressId });
        if (editedAddress) {
            const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack, mobile_country_code } = editedAddress;
            let newAddressFormState = {
                name: name || "",
                mobile_code: mobile_country_code || "",
                mobile: mobile || "",
                email: email || "",
                identity_no: identity_no || "",
                address: address || "",
                address2: address2 || "",
                city,
                state,
                zip,
                country,
                icFront,
                icBack
            };
            if (_.size(icFront) > 0) {
                newAddressFormState['icFront'] = icFront.file_name;
            }
            if (_.size(icBack) > 0) {
                newAddressFormState['icBack'] = icBack.file_name;
            }

            setAddressFormState(newAddressFormState);
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: selectedAddressId }));
        }
    }

    const closeModal = () => {
        setState(state => ({ ...state, modalShow: false, mode: null, 'editAddressId': null }));
        setAddressFormState(INITIAL_ADDRESS_STATE);
        setAddressMobileCode({});
    };

    const submitAddress = () => {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        addAlert(result.message, 'success');
                        setState(state => ({ ...state, modalShow: false }));
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                            let defaultAddress = _.find(addressList.data, { 'default': true });
                            if (_.size(defaultAddress) > 0) {
                                setState(state => ({ ...state, addressId: defaultAddress.id }))
                            } else {
                                if (_.size(addressList.data)) {
                                    const firstAddress = addressList.data[0];
                                    setState(state => ({ ...state, addressId: firstAddress.id }))
                                }
                            }
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        const refreshMall = (state.editAddressId === state.addressId);
                        setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null }));
                        addAlert(result.message, 'success');
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                            if (refreshMall) {
                                setMallShippingRefresh(!mallShippingRefresh);
                            }
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
    }

    const noAddress = () => {
        return (
            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                {
                    addresses === null ?
                        <>
                            <CircularProgress disableShrink />
                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                        </>
                        :
                        <Typography variant="caption">{t('checkout.noAddress')}</Typography>
                }
            </Box>
        )
    }

    const addressCard = (addressItem, list = true) => {
        let selected = addressItem.id === state.addressId ? true : false;
        let bgColor = (selected && list) ? theme.palette.primary.main : 'white';
        let txColor = (selected && list) ? 'white' : theme.palette.primary.main;
        let clClass = (selected && list) ? 'cl-white' : 'cl-theme';

        return (
            <div className='glass2 bor15 shadow-glass2 p-all-15' key={addressItem.id} style={{ color: txColor, backgroundColor: bgColor, position: "relative" }}>
                {
                    list
                    &&
                    <IconButton style={{ zIndex: 1, position: "absolute", right: 0, top: 0 }} onClick={() => openEditAddress(addressItem.id)} disabled={fetchingMallShipping}>
                        <Edit className={`fs-18 ${clClass}`} />
                    </IconButton>
                }
                <div onClick={() => setState({ ...state, addressId: addressItem.id })} disabled={fetchingMallShipping} >
                    <div className='flex-sb-m w-full'>
                        <div className={`p-r-10 ${clClass}`}>
                            <p className='fs-19' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}><b>{`${addressItem.name || '-'}`}</b></p>
                            <p className='fs-14' style={{ whiteSpace: 'nowrap' }}><b>{`${addressItem.mobile_country_code !== null ? `${addressItem.mobile_country_code} - ` : ''} ${addressItem.mobile || '-'}`}</b></p>
                        </div>
                    </div>
                    <p className={`fs-14 p-t-10 ${clClass}`} style={{ textAlign: 'justify' }}>
                        {` ${addressItem.address}, ${addressItem.address2_display}, ${addressItem.city_display}, ${addressItem.state_display}, ${addressItem.zip}, ${addressItem.country_display}`}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='w-full'>
                <div className='flex-sb-m w-full' style={{ minWidth: 360 }}>
                    <p className='txt-center cl-label fs-18'><b>{t('checkout.shippingAddress')}</b></p>
                    <p className='fs-14 p-l-8 pointer button-theme-outlined p-tb-5 p-lr-15' onClick={handleClickOpen} style={{ whiteSpace: 'nowrap' }}>{t('button.changeAddress')}</p>
                </div>
                <div className='w-full p-t-15'>
                    {
                        _.size(addresses) > 0 ?
                            _.map(addresses, (addressItem) => {
                                if (state.addressId == addressItem.id) {
                                    return addressCard(addressItem, false);
                                }
                            })
                            :
                            noAddress()
                    }
                </div>
            </div>
            {/* ADDRESS LIST */}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0 }}>{t('button.changeAddress')}</p>
                        <div className='btn-square30-theme translateX' onClick={openAddAddress} disabled={fetchingMallShipping}>
                            <Plus className='clwhite fs-icon'/>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        _.size(addresses) > 0 ?
                            _.map(addresses, (addressItem) => {
                                return (
                                    <div key={addressItem.id} style={{ marginBottom: 15 }}>
                                        {addressCard(addressItem)}
                                    </div>
                                )
                            })
                            :
                            noAddress()
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary" variant="contained">
                        {t('button.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ADDRESS DETAILS */}
            <Dialog open={state.modalShow} fullWidth onClose={closeModal}>
                <DialogTitle>
                    <p>{state.mode === 'add' ? t('address.addNewAddress') : t('address.editAddress')}</p>
                    <IconButton className={styles.closeButton} onClick={closeModal}>
                        <CloseOutlined style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ padding: 30 }} className={styles.dialogContentRoot}>
                    <Grid container spacing={3} direction="column">
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.recipient')}</Typography>
                            <TextField
                                onChange={handleAddressChange}
                                variant="outlined"
                                type="text"
                                inputProps={{ name: 'name' }}
                                value={addressFormState.name}
                                error={errorAddressState.name ? true : false}
                                helperText={errorAddressState.name}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.mobileCode')}</Typography>
                            <Select
                                native
                                value={addressFormState.mobile_code}
                                onChange={handleAddressChange}
                                inputProps={{ name: 'mobile_code' }}
                                error={errorAddressState.mobile_code ? true : false}
                                disabled={_.size(addressMobileCode) >  0 ? false:true}
                            >
                                {_.size(addressMobileCode) > 0 ?
                                 _.map(addressMobileCode, item => (
                                    <option key={item} value={item}>{item}</option>
                                )):
                                <option value="">{t('address.pleaseSelect')}{t('address.mobileCode')}</option>
                                }
                            </Select>
                            {
                                addressFormState?.mobile_code > 0 ? <></> : <FormHelperText>{t('general.pleaseSelectCountry')}</FormHelperText>
                            }
                            <FormHelperText style={{ color: '#ff1744' }}>{errorAddressState.mobile_code}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.mobile')}</Typography>
                            <TextField
                                onChange={handleAddressChange}
                                variant="outlined"
                                type="text"
                                inputProps={{ name: 'mobile' }}
                                value={addressFormState.mobile}
                                error={errorAddressState.mobile ? true : false}
                                helperText={errorAddressState.mobile}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.email')}</Typography>
                            <TextField
                                onChange={handleAddressChange}
                                variant="outlined"
                                type="text"
                                inputProps={{ name: 'email' }}
                                value={addressFormState.email}
                                error={errorAddressState.email ? true : false}
                                helperText={errorAddressState.email}
                            />
                        </FormControl>
                        {shouldUploadIC &&
                            <>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{t('address.identity_no')}</Typography>
                                    <TextField
                                        onChange={handleAddressChange}
                                        variant="outlined"
                                        type="text"
                                        inputProps={{ name: 'identity_no' }}
                                        value={addressFormState.identity_no}
                                        error={errorAddressState.identity_no ? true : false}
                                        helperText={errorAddressState.identity_no}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography variant="body2">{t('checkout.chinaRecipientNeedIC')}</Typography>
                                    <Box display="flex" flexDirection="row">
                                        <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                        <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icFrontRef.current.click()}
                                            variant="outlined"
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                {addressFormState.icFront ?
                                                    <img src={addressFormState.icFront} alt="ic front" />
                                                    : <ArtTrackOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icFront')}</Typography>
                                            </Box>
                                        </Button>
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icBackRef.current.click()}
                                            variant="outlined"
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                {addressFormState.icBack ?
                                                    <img src={addressFormState.icBack} alt="ic back" />
                                                    : <AspectRatioOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icBack')}</Typography>
                                            </Box>
                                        </Button>
                                    </Box>
                                    <Typography variant="caption" color="error">{errorAddressState.icFront}</Typography>
                                    <Typography variant="caption" color="error">{errorAddressState.icBack}</Typography>
                                </FormControl>
                            </>
                        }
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.country')}</Typography>
                            <Select
                                native
                                value={addressFormState.country}
                                onChange={handleAddressChange}
                                inputProps={{ name: 'country' }}
                                error={errorAddressState.country ? true : false}
                            >
                                <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                                {_.map(countries, countryItem => (
                                    <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                ))}
                            </Select>
                            <FormHelperText>{errorAddressState.country}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.zip')}</Typography>
                            <TextField
                                inputProps={{ name: 'zip' }}
                                onChange={handleAddressChange}
                                type="text"
                                variant="outlined"
                                value={addressFormState.zip}
                                error={errorAddressState.zip ? true : false}
                                helperText={errorAddressState.zip}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.state')}</Typography>
                            {_.includes(addressGroup.stateGroup, addressFormState.country) ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.state}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'state' }}
                                        error={errorAddressState.state ? true : false}
                                    >
                                        <option value="">{t('address.pleaseSelect')}{t('address.state')}</option>
                                        {
                                            _.map(addressState, stateItem => {
                                                return (
                                                    <option key={stateItem.id} value={stateItem.id}>{stateItem.name_display}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.state}</FormHelperText>
                                </>
                                :
                                <TextField
                                    onChange={handleAddressChange}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ name: 'state' }}
                                    value={addressFormState.state}
                                    error={errorAddressState.state ? true : false}
                                    helperText={errorAddressState.state}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.city')}</Typography>
                            {_.size(cities) > 0 ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.city}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'city' }}
                                        error={errorAddressState.city ? true : false}
                                    >
                                        <option value="">{t('address.pleaseSelect')}{t('address.city')}</option>
                                        {
                                            _.map(cities, cityItem => {
                                                return (
                                                    <option key={cityItem.id} value={cityItem.id}>{cityItem.name_display}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.city}</FormHelperText>
                                </>
                                :
                                <TextField
                                    onChange={handleAddressChange}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ name: 'city' }}
                                    value={addressFormState.city}
                                    error={errorAddressState.city ? true : false}
                                    helperText={errorAddressState.city}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.address2')}</Typography>
                            {_.size(district) > 0 ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.address2}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'address2' }}
                                        error={errorAddressState.address2 ? true : false}
                                    >
                                        <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                        {
                                            _.map(district, districtItem => {
                                                return (
                                                    <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                </>
                                :
                                <TextField
                                    onChange={handleAddressChange}
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ name: 'address2' }}
                                    value={addressFormState.address2}
                                    error={errorAddressState.address2 ? true : false}
                                    helperText={errorAddressState.address2}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{t('address.address')}</Typography>
                            <TextField
                                onChange={handleAddressChange}
                                type="text"
                                variant="outlined"
                                inputProps={{ name: 'address' }}
                                value={addressFormState.address}
                                error={errorAddressState.address ? true : false}
                                helperText={errorAddressState.address}
                                multiline
                                rows={3}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button variant="contained" onClick={submitAddress} color="secondary">
                        {state.mode === 'add' ? t('button.add') : t('button.update')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: 5
    },
    dialogContentRoot: {
        padding: theme.spacing(2),
    },
    dialogActionRoot: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
}));

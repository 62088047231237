import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '@helper/Tools';

export default function TransactionItem({ data, openDialog, viewDetail = true }) {
    const { amount, factor, remark, created_at, type_display } = data;
    const { t, i18n } = useTranslation();
    let operator = factor > 0 ? "+" : "-";

    return (
        <div className='p-t-15'>
            <div className={`w-full flex-sb-m p-b-15 ${viewDetail && 'pointer'}`} onClick={viewDetail ? () => openDialog(data) : () => { }}>
                <div style={{ width: 'calc(100% - 150px)' }}>
                    <p className='fs-content txt_truncate'><b>{_.hasIn(type_display, i18n.language) ? type_display[i18n.language] : '-'}</b></p>
                    <p className='fs-remark txt_truncate'>{`${t('transaction.remark')}: ${remark || '-'}`}</p>
                </div>

                <div className='txt-right' style={{ whiteSpace: 'nowrap' }}>
                    <p className='fs-content' style={{ color: operator === '+' ? "green" : 'red', }}><b>{`${operator} ${currencyFormat((amount * 100) / 100)}`}</b></p>
                    <p className='fs-remark'>{created_at}</p>
                </div>
            </div>
            <div className='divider-white p-tb-2' />
        </div>
    )
}
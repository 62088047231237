import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid, Typography, Link, Box, Container, List, ListItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// import StarsIcon from '@material-ui/icons/Stars';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import _ from 'lodash';

import { getUrl, putUrl, postUrl } from '@helper/ApiAction'; 
import useNotificationLoading from '@helper/useNotificationLoading';
// import WebpImg from '@layouts/WebpImg';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Rating } from '@material-ui/lab';

import WebpImg from '@layouts/WebpImg';


export default function OrderDetail(props) {
    const { t, i18n, oid } = props;
    const styles = useStyles();
    const { walletList:wallets, username } = useSelector(state => ({
        walletList: state.wallet.walletList,
        username: state.user.username,
    }));

    const [order, setOrder] = useState([]);
    const isMountedRef = useRef(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [trackingData, setTrackingData] = useState([]);
    const [orderDetailsNoTracking, setOrderDetailsNoTracking] = useState([]);
    const [orderDetailsCancel, setOrderDetailsCancel] = useState([]);
    const [trackingDetailsOpen, setTrackingDetailsOpen] = useState([]);
    const [showRating, setShowRating] = useState(false);
    // const [wallets, setWallets] = useState([]);
    const [shippingWalletId, setShippingWalletId] = useState([]);
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    let history = useHistory();

    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();

    const {currency,currencyRate,currencyDisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencyDisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
        
    });

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`logistic/update_tracking_payment_parcel_status/${oid}`).then(response => {
            // no need show update error for member
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        getUrl(`orders/${oid}`)
        .then(orderList => {
            if(isMountedRef.current) {
                if(orderList.status === 1 && _.size(orderList.data) > 0) {
                    setCurrencyData(orderList.currency_data);
                    setOrder(orderList.data);
                    setTrackingData(orderList.order_tracking);
                    setOrderDetailsNoTracking(orderList.order_details_no_tracking);
                    setOrderDetailsCancel(orderList.order_details_cancelled);
                    let totalPrice = 0;
                    // if(!_.isUndefined(orderList.data) && !_.isNull(orderList.data)) {
                        _.map(orderList.data.order_details, order => {
                            totalPrice += parseFloat(order.price);
                        });
                        setOrder(order => ({ ...order, order_total: totalPrice }));
                    // }
                    getUrl('shipping_fee/wallet').then(response => {
                        if(response.status){
                            if(_.size(response.data) > 0){
                                setShippingWalletId(response.data.id);
                            }else{
                                setShippingWalletId('cash');
                            }
                        }else{
                            addAlert(JSON.stringify(response.data));
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                } else {
                    addAlert(t('order.invalidOrder'));
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        // getUrl('wallets').then(response => {
        //     if (isMountedRef.current && response.status === 1) {
        //         setWallets(response.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, refreshData]);

    const handleTrackingDetailsClick = (trackingId) => {
        setTrackingDetailsOpen({ ...trackingDetailsOpen, [trackingId]: !trackingDetailsOpen[trackingId]});
    }

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.confirmCompletedSuccess'), 'success');
                setRefreshData(!refreshData);
            }else{
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    const chatWithMerchant = merchantId => {
        if (merchantId) {
            postUrl(`chats`, {
                'merchant_id': merchantId,
            }).then(response => {
                if (response.status === 1) {
                    history.push('/chat');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const chatWithAdmin = () => {
        postUrl('adminchats').then(response => {
            // console.log("adminchats",response);
            if (response.status) {
                history.push(`/customer-service`);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    // const orderRefund = () => {
    //     history.push("/account/refund/" + oid);
    // }

    const cancelOrder = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark
        };
        postUrl(`orders/${cancelOrderDialog.order_no}/cancel_order`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.cancelSuccess'), 'success');
                setRefreshData(!refreshData);
                handleCancelOrderClose();
            }else{
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            setCancelOrderDialog({ ...cancelOrderDialog, open: true });
            addAlert(JSON.stringify(error.message));
        });
    }

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            paymentItem = paymentItem*currencyRate[`${currency}`]
            prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
        }else{
            prefix = paymentIndex;
        }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    let totalPrice = {};
    let totalShipping = {};
    let subtotalPrice = {};
    let rateDone = false;

    const orderDetailsDisplay = (orderItem) => {
        if((order.status === 60 || orderItem.status) && _.size(orderItem.payment) > 0) {
            _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                if(paymentIndex ==="multi_wallet"){
                    let multiP = JSON.parse(orderItem.multi_pricing);
                    let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                    paymentIndex = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                }
                if(_.isUndefined(totalPrice[paymentIndex])) {
                    totalPrice[paymentIndex] = 0;
                }
                if(_.isUndefined(subtotalPrice[paymentIndex])) {
                    subtotalPrice[paymentIndex] = 0;
                }
                totalPrice[paymentIndex] += parseFloat(paymentItem);
                subtotalPrice[paymentIndex] += parseFloat(paymentItem);
            })
        }
        return (
            <>
            <div className='w-full flex-sb-m'>
                <div>
                    <Link underline='none' color="inherit" to={`/product/${orderItem.product_id}`} component={RouterLink}>
                        <div className='flex-c-m bor15 bg-theme' style={{height: 90, minWidth:90, width: 90,}}> {/* png yi background color FOLLOW PRODUCT COLOR */}
                            <WebpImg src={orderItem.image} alt="product" className="bor15" style={{ height: 90, minWidth: 90, width: 90, objectFit: 'cover' }}/>
                        </div>
                    </Link>
                </div>
                <div style={{width:'100%', paddingLeft:15}}>
                    <Link underline='none' color="inherit" to={`/product/${orderItem.product_id}`} component={RouterLink}>
                        <p className='fs-title p-b-5'>{orderItem.title_display}</p>
                    </Link>
                    {
                        (_.has(orderItem.product_attribute_display, 'color') || _.has(orderItem.product_attribute_display, 'size'))
                        &&
                        <div className='box-variant'>
                            {
                                _.map(orderItem.product_attribute_display, (variantItem, variantIndex) => {
                                    if (_.includes(['color', 'size'], variantIndex)) {
                                        return (
                                            <p key={variantItem} style={{ width: 'fit-content' }} className='p-lr-5 fs-content'>{variantItem > 0 ? ", " : ""}{variantItem}</p>
                                        )
                                    }
                                })
                            }
                        </div>
                    }
                    
                    <div className='flex-sb w-full'>
                        <div>
                            {
                                _.size(orderItem.payment) > 0 ?
                                    _.map(orderItem.payment, (paymentItem, paymentIndex) => {
                                        if (parseFloat(paymentItem) > 0) {
                                            return (
                                                <div key={paymentIndex}>
                                                    <p className='fs-content'>
                                                        {priceDisplay(paymentItem, paymentIndex, orderItem)}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    })
                                    :
                                    <p className='fs-remark'>
                                        <NumberFormat value={parseFloat(orderItem.price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                    </p>

                            }
                            {/* <Typography variant="caption">
                                    <NumberFormat className={styles.priceStyle} value={orderItem.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                                </Typography> */}
                        </div>
                        <p className='p-l-10 fs-content'>x {orderItem.quantity}</p>
                    </div>
                        
                    {
                        orderItem.status
                            ?
                            null
                            :
                            <p className='fs-remark p-tb-5 p-lr-10 bor50 cl-text' style={{border: "1px solid grey", width:'fit-content' }}>
                                {t('order.productCancelled')}
                            </p>
                    }
                </div>
            </div>
            {/* showRating &&  */}
                {
                    _.size(orderItem.product_review) > 0 &&
                    <div className='p-t-15'>
                        <div>
                            <Rating
                                defaultValue={orderItem.product_review.rating}
                                precision={1}
                                readOnly
                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                        </div>
                        {
                            _.size(orderItem.product_review.comment) > 0 &&
                            <div>
                                <p className='fs-remark'><b className='txt-upper'>{`${t('order.review')}: `}</b> {orderItem.product_review.comment}</p>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            {order ?
                <div className='w-full'>
                    {
                        _.size(order.order_shippings) > 0 ?
                            _.map(order.order_shippings, shippingValue => {
                                let countShipping = false;
                                if (order.status === 60) {
                                    countShipping = true;
                                } else {
                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                        _.map(order.order_details, orderDetails => {
                                            if (orderDetails.id === shipingDetail.order_detail_id) {
                                                if (orderDetails.status) {
                                                    countShipping = true;
                                                }
                                            }
                                        })
                                    })
                                }
                                if (countShipping) {
                                    if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                        let wid = shippingWalletId;
                                        if (shippingValue.wallet_id > 0) {
                                            wid = shippingValue.wallet_id;
                                        }
                                        if (_.isUndefined(totalPrice[wid])) {
                                            totalPrice[wid] = 0;
                                        }
                                        if (_.isUndefined(totalShipping[wid])) {
                                            totalShipping[wid] = 0;
                                        }
                                        totalPrice[wid] += parseFloat(shippingValue.fee);
                                        totalShipping[wid] += parseFloat(shippingValue.fee);
                                    }
                                }
                            })
                            : null
                    }
                    <div className='bg-theme p-b-80 header-radius'>
                        {/* <Link underline='none' color="inherit" to={history.back()} component={RouterLink}>
                                <Box display="flex" alignItems="center" onClick={() => history.goBack()} component={Button}>
                                    <KeyboardArrowLeftIcon fontSize="default" style={{ padding: 5 }} />
                                    <Typography variant="caption">
                                        {t('button.back')}
                                    </Typography>
                                </Box>
                            </Link> */}
                        <Container>
                            <div className='clwhite'>
                                <p className='fs-header'>
                                    <b>{`${order.status_display ? order.status_display : '-'}`}</b>
                                </p>
                                <p className='fs-subheader'>
                                    {t('order.id')}: {order.order_no}
                                </p>
                            </div>
                        </Container>
                    </div>


                    
                    <Container>
                        {
                            (order.recipient || order.mobile)
                            &&
                            <div className="glass2 shadow-glass2 bor15 p-all-15" style={{ marginTop: '-56px' }}>
                                {/* <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('order.deliveryAddress')}</Typography> */}
                                <div className='cl-theme fs-content shadow-txt'>
                                    <p className='fs-title txt-upper'>{order.recipient}</p>
                                    <p >{order.mobile}</p>
                                    <p className='p-t-10'>{order.full_address ? order.full_address : ''}</p>
                                </div>
                                {/* <Typography variant="caption">{ order.address ? (`${order.address}, ${order.state}, ${order.city}, ${order.zip}, ${order.country}`) : '' }</Typography> */}
                                {order.recipient_username && order.recipient_username !== username &&
                                    <>
                                        <div className='divider-white m-tb-15' style={{ height: 6 }} />
                                        <div className='cl-theme shadow-txt'>
                                            <p className='fs-title'>{t('order.bvBeneficiary')}</p>
                                            <p className='fs-content'>{order.recipient_username}</p>
                                        </div>
                                    </>
                                }
                            </div>
                        }

                       

                    {
                        _.map(order.order_details, (outletItem, outletIndex) => {
                            let showOutlet = false;
                            if (_.size(outletItem.outlet) > 0) {
                                showOutlet = true
                            }
                            if (showOutlet) {
                                return (
                                    <div className='p-t-25' key={outletIndex}>
                                        <div className='bor15 p-lr-15 p-b-15 glass2 shadow-glass2 cl-theme'>
                                            <div className='p-tb-8 p-lr-20 label-box'>
                                                <p className='p-l-5 fs-title'>
                                                    {t('order.outletDetail')}
                                                </p>
                                            </div>
                                            <div className='p-t-15'>
                                                <p className='fs-title'>{outletItem.outlet.pic_name}</p>
                                                <p className='subtitle'>{outletItem.outlet.pic_mobile}</p>
                                                <div className='fs-content p-t-10'>
                                                    <p><b>{outletItem.outlet.outlet_name}</b></p>
                                                    <p>{outletItem.outlet.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })
                    }

                    {
                        trackingData ?
                            _.map(trackingData, (trackingDetails) => {
                                return (
                                    <div className='p-t-25' key={trackingDetails.tracking_data.id}>
                                        <div className='glass2 shadow-glass2 p-all-15 bor15'>
                                            <p className='fs-content'>{t('order.trackingNo')} : {trackingDetails.tracking_data.tracking_no}</p>
                                            {
                                            trackingDetails.tracking_data.logistic_parcel_status ?
                                                <div>
                                                    <List
                                                        component="nav"
                                                        disablePadding
                                                    >
                                                        <ListItem disableGutters button onClick={() => handleTrackingDetailsClick(trackingDetails.tracking_data.id)}>
                                                            {/* <ListItemText primary={t('order.trackingStatus') + ': ' + trackingDetails.logistic_parcel_status} /> */}
                                                                <div className='w-full flex-sb-m'>
                                                                    <p>{trackingDetails.tracking_data.logistic_parcel_status}</p>
                                                                    {trackingDetailsOpen[trackingDetails.tracking_data.id] ? <ExpandLess /> : <ExpandMore />}
                                                                </div>
                                                        </ListItem>
                                                        <Collapse in={trackingDetailsOpen[trackingDetails.tracking_data.id]} timeout="auto" unmountOnExit>
                                                            <div>
                                                                {Object.values(trackingDetails.tracking_data.tracking_details).map((row, key) => {
                                                                    return (
                                                                        <ListItem key={key}>
                                                                            <ListItemIcon>
                                                                                <LocalShippingIcon fontSize="small" />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={row.status} secondary={row.event_date + ' ' + row.event_time} />
                                                                        </ListItem>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Collapse>
                                                    </List>
                                                </div>
                                                :
                                                (
                                                    trackingDetails.tracking_data.status_display ?
                                                        <div>
                                                            {/* <p className='fs-title'>{`${t('order.trackingStatus')}: ${trackingDetails.tracking_data.status_display}`}</p> */}
                                                            <p className='fs-title cl-theme'>{trackingDetails.tracking_data.status_display}</p>
                                                        </div>
                                                        :
                                                        <div>
                                                            <p className='fs-remark'>{`${t('order.trackingStatus')}: -`}</p>
                                                        </div>
                                                )
                                        }
                                        </div>
                                        <div className='p-t-25'>
                                            {
                                                _.map(trackingDetails.data_list, (tdetails) => {
                                                    return (
                                                        <div key={tdetails.id} className='p-b-25'>
                                                            {orderDetailsDisplay(tdetails)}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            :
                            null
                    }

                    {
                        orderDetailsNoTracking || orderDetailsCancel || rateDone ?
                            <>
                                {
                                    orderDetailsNoTracking ?
                                        <div className="p-tb-25">
                                            <p className='fs-title p-b-10'>{t('order.processingItem')}</p>
                                            {
                                                _.map(orderDetailsNoTracking, (orderItem) => {
                                                    return (
                                                        <div key={orderItem.id} className='p-all-15 bor15 shadow-out m-b-15'>
                                                            {orderDetailsDisplay(orderItem)}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        null
                                }
                                {
                                    _.size(orderDetailsCancel) ?
                                            <div className="p-tb-25">
                                                <div>
                                                    <p className='cl-theme fs-title'><b>{t('order.cancelItem')}</b></p>
                                                    <div>
                                                        {
                                                            _.map(orderDetailsCancel, (orderItem) => {
                                                                return (
                                                                    <div key={orderItem.id} style={{ margin: 10 }}>
                                                                        {orderDetailsDisplay(orderItem)}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null
                                }

                                {/* <>
                                    {
                                        _.map(order.order_details, (orderItem) => {
                                            if (_.size(orderItem.product_review) > 0) {
                                                rateDone = true;
                                            }
                                        })
                                    }
                                    {
                                        rateDone ?
                                            <Button color="secondary" variant="outlined" style={{ width: '100%', marginTop: 5 }} startIcon={<StarsIcon fontSize="inherit" />} onClick={() => setShowRating(!showRating)}>
                                                <Typography style={{ fontSize: 12 }}>{showRating ? t('order.hideRating') : t('order.showRating')}</Typography>
                                            </Button> : null
                                    }
                                </> */}
                            </>
                            : null
                    }

                    </Container>

                    

                    

                    {/* <Box className={styles.whiteBox}>
                        {
                            orderDetailsNoTracking ?
                                <Grid container>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 10 }}>{t('order.processingItem')}</Typography>
                                    <Grid item xs={12}>
                                        {
                                            _.map(orderDetailsNoTracking, (orderItem) => {
                                                return (
                                                    <Box key={orderItem.id}>
                                                        {orderDetailsDisplay(orderItem)}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                        {
                            orderDetailsCancel ?
                                <Box>
                                    <ListItem>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" color="secondary">{t('order.cancelItem')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    _.map(orderDetailsCancel, (orderItem) => {
                                                        return (
                                                            <Box key={orderItem.id} style={{ margin: 10 }}>
                                                                {orderDetailsDisplay(orderItem)}
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Box>
                                :
                                null
                        }

                        <Box>
                            {
                                _.map(order.order_details, (orderItem) => {
                                    if (_.size(orderItem.product_review) > 0) {
                                        rateDone = true;
                                    }
                                })
                            }
                            {
                                rateDone ?
                                    <Button variant="text" color="secondary" variant="outlined" style={{ width: '100%', marginTop: 5 }} startIcon={<StarsIcon fontSize="inherit" />} onClick={() => setShowRating(!showRating)}>
                                        <Typography style={{ fontSize: 12 }}>{showRating ? t('order.hideRating') : t('order.showRating')}</Typography>
                                    </Button> : null
                            }
                        </Box>
                    </Box> */}

                    {/* Remark */}
                    <div>
                        <div style={{ height: 4, background: '#E8E8E8' }} />
                        <Container className='p-tb-20'>
                            <p className='fs-title'>{t('checkout.remark')}</p>
                            <p className='fs-content cl-label'>{order.remark ? order.remark : '-'}</p>
                        </Container>
                        <div style={{ height: 4, background: '#E8E8E8' }} />
                    </div>

                    {/* Subtotal */}
                    <div>
                        <Container className='p-tb-20'>
                            <div className='flex-sb w-full p-b-15'>
                                <p>{t('order.subtotal')}</p>
                                <div>
                                    {
                                        _.size(subtotalPrice) ?
                                            _.map(subtotalPrice, (priceItem, priceIndex) => {
                                                if (parseFloat(priceItem) > 0) {
                                                    return (
                                                        <p className='fs-content' key={priceIndex} style={{ width: 'fit-content' }}>
                                                            {priceDisplay(priceItem, priceIndex)}
                                                        </p>
                                                    )
                                                }
                                            })
                                            :
                                            <p className='fs-content' style={{ width: 'fit-content' }}>0.00</p>
                                    }
                                </div>
                                {/* <Typography variant="caption">
                                    <NumberFormat className={styles.subTotalStyle} value={order.order_total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                                </Typography>     */}
                            </div>

                            <div className='flex-sb w-full p-b-15 fs-content'>
                                <p>{t('order.shippingFee')}</p>
                                {
                                    _.size(totalShipping) ?
                                        _.map(totalShipping, (shippingValue, shippingIndex) => {
                                            return (
                                                <p key={shippingIndex}>
                                                    {priceDisplay(shippingValue, shippingIndex)}
                                                </p>
                                            )
                                        })
                                        :
                                        <p>
                                            {t('order.freeShipping')}
                                        </p>
                                }
                            </div>

                            <div className='flex-sb w-full fs-title'>
                                <p>{t('order.orderTotal')}</p>
                                <div className='txt-right'>
                                    {
                                        _.size(totalPrice) ?
                                            _.map(totalPrice, (priceItem, priceIndex) => {
                                                if (parseFloat(priceItem) > 0) {
                                                    return (
                                                        <p key={priceIndex}>
                                                            <b>{priceDisplay(priceItem, priceIndex)}</b>
                                                        </p>
                                                    )
                                                }
                                            })
                                            :
                                            <p><b>0.00</b></p>
                                    }
                                </div>
                            </div>
                        </Container>
                    </div>

                    <Container>
                    <div className='p-t-20'>
                        {/* <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} style={{ marginRight: 20 }} onClick={() => chatWithAdmin()} >
                                <Typography variant="caption">
                                    {t('order.contactAdmin')}
                                </Typography>
                            </Button>
                            <Button size="large" variant="contained" color="secondary" className={styles.buttonStyle} style={{ marginRight: 20 }} onClick={() => chatWithMerchant(order.merchant_company.id)} >
                                <Typography variant="caption">
                                    {t('order.contactSeller')}
                                </Typography>
                            </Button> */}
                        {
                            (order.status === 35 || order.status === 40)
                                ?
                                // <Grid item xs={12} style={{ textAlign: 'end' }}>
                                <div className='btn-theme w-full' onClick={() => confirmCompleted(order.order_no)}>
                                    <p className='fs-button'>
                                        {t('order.confirmCompleted')}
                                    </p>
                                </div>
                                // </Grid>
                                :
                                null
                        }
                        {/* {
                            (order.status === 35 || order.status === 40)
                            ?
                            // <Grid item xs={12} style={{ textAlign: 'end' }}>
                            <Button size="large" variant="contained" color="secondary" className={ styles.buttonStyle } style={{marginRight: 20}} onClick={ () => orderRefund() }>
                                <Typography variant="caption">
                                    { t('order.requestRefund') }
                                </Typography>
                            </Button>
                            // </Grid>
                            :
                            null
                        } */}
                        {
                            order.status === 20
                                ?
                                <div className='btn-outlined-theme w-full' onClick={() => handleCancelOrderOpen(order.order_no)}>
                                    <p className='fs-button'>
                                        {t('order.cancelOrder')}
                                    </p>
                                </div>
                                :
                                null
                        }
                    </div>
                    </Container>
                </div>
                : null
            }


            {/* <Grid item xs={12}>
                {
                    order && _.size(order.refund) > 0
                    ?
                    <Grid container style={{padding: 20, backgroundColor: '#fff'}}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">{t('refund.refund')}</Typography>
                        </Grid>
                        {
                            _.map(order.refund, refundDetail => {
                                let returnItem = '';
                                {
                                    _.map(refundDetail.order_detail_array, od => {
                                        _.map(order.order_details, details => {
                                            if(parseInt(details.id) === parseInt(od)){
                                                returnItem += returnItem?(' , ' + details.title_display):details.title_display;
                                            }
                                        })
                                    })
                                }
                                return (
                                    <Box key={refundDetail.id}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.applyDate')}:</Typography>
                                            <Typography>{refundDetail.created_at}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.applyStatus')}:</Typography>
                                            <Typography>{refundDetail.status_display}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.reason')}:</Typography>
                                            <Typography>{refundDetail.reason}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.remark')}:</Typography>
                                            <Typography>{refundDetail.remark?refundDetail.remark:'-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }}>{t('refund.items')}:</Typography>
                                            <Typography>{returnItem}</Typography>
                                        </Grid>
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                    :
                    null
                }
            </Grid> */}
            <Dialog open={ cancelOrderDialog.open } onClose={ handleCancelOrderClose } >
                <DialogTitle>
                    { t('order.cancelOrder') }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} justify="center" >
                        <Grid item xs={12}>
                            { t('order.cancelOrderReminder') }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true} 
                                variant="outlined" 
                                label={t('order.cancelOrderRemark')}
                                multiline
                                value={ cancelOrderDialog.remark }
                                onChange={ (event) => { setCancelOrderDialog({ ...cancelOrderDialog , remark: event.target.value }) }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" className={ styles.buttonStyle } onClick={ () => cancelOrder()} >
                        <Typography variant="overline">{ t('order.confirmCancelOrder') }</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    icon: {
        color: `${theme.palette.green.main} !important `,
    },
    priceStyle: {
        fontSize: 14,
        color: theme.palette.secondary.main,
    },
    subTotalStyle: {
        fontSize: 16,
    },
    totalStyle: {
        fontSize: 18,
        color: theme.palette.secondary.main,
        fontWeight: 800
    },
    whiteBox: {
        borderRadius:10,
        backgroundColor: theme.palette.white.main,
        padding:15,
        width: '90%',
        margin: '0 auto',
        boxShadow: '0 0 12px 0 #0000001f',
        marginBottom: 20
    }
}));
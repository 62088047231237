import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '@actions';
import _ from 'lodash';
import { putUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

//MUI
import { TextField, Container, Typography, Snackbar, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

//ICON
// import { FiUser } from "react-icons/fi";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

export default function ProfileBankInfo() {
    // const styles = useStyles();
    const { t } = useTranslation();
    // const theme = useTheme();
    const dispatch = useDispatch();

    const { bank_account, bank_holder_name, bank_name, bank_swift} = useSelector(state => state.user);
    const [profileFormState, setProfileFormState] = useState({ bank_account, bank_holder_name, bank_name, bank_swift });
    const [errorFormState, setErrorFormState] = useState({
        bank_account: '',bank_info: '', bank_swift: '', bank_holder_name: ''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    // const [disabledState, setDisabledState] = useState({
    //     bank_holder_name: false
    // });

    const { setLoading } = useNotificationLoading();

    // useEffect(() => {
    //     if(bank_holder_name){
    //         setDisabledState({ ...disabledState, bank_holder_name: true });
    //     }
    //     // eslint-disable-next-line
    // }, [bank_holder_name]);

    const handleInfoChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const updateInfo = () => {
        setLoading(true);
        putUrl('user/bank', profileFormState).then(result => {
            if(result.error) {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                let allErrorField = [];
                _.map(result.error, (errorItem, errorField) => {
                    allErrorField[errorField] = errorItem[0];
                })
                setErrorFormState(allErrorField);
            } else {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                dispatch(storeProfile(result.data));
                setErrorFormState({});
            }
        })
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" spendButton />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('profile.bankInfo')}</p>
                    <p className='fs-subheader cl-label'>{t('profile.bankInfoSubtitle')}</p>
                </div>
            </div>
            <Container>
                {/* <div className='p-tb-15'>
                    <Typography variant="h6" className='cl-theme'>
                        {t('profile.bankInfo')}
                    </Typography>
                    <p className='cl-label fs-13'>
                        {t('profile.bankInfoSubtitle')}
                    </p>
                </div>
                <div className='divider-white p-tb-4' /> */}
                <div className='p-t-25'>
                    <p className="fs-textfield">{t('profile.bankName')}</p>
                    <TextField
                        // label={t('profile.bankName')}
                        error={errorFormState.bank_name ? true : false}
                        helperText={errorFormState.bank_name}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        inputProps={{ name: 'bank_name' }}
                        onChange={handleInfoChange}
                        value={profileFormState.bank_name ?? ""}
                        // placeholder={t('profile.bankName')}
               
                    />
                </div>
                <div className='p-t-25'>
                    <p className="fs-textfield">{t('profile.bankHolderName')}</p>
                    <TextField
                        // label={t('profile.bankHolderName')}
                        error={errorFormState.bank_holder_name ? true : false}
                        helperText={errorFormState.bank_holder_name}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        inputProps={{ name: 'bank_holder_name' }}
                        onChange={handleInfoChange}
                        value={profileFormState.bank_holder_name ?? ""}
                    />
                </div>
                <div className='p-t-25'>
                    <p className="fs-textfield">{t('profile.bankAccount')}</p>
                    <TextField
                        // label={t('profile.bankAccount')}
                        error={errorFormState.bank_account ? true : false}
                        helperText={errorFormState.bank_account}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        inputProps={{ name: 'bank_account' }}
                        onChange={handleInfoChange}
                        value={profileFormState.bank_account ?? ""}
                    />
                </div>
                <div className='p-t-25'>
                    <p className="fs-textfield">{t('profile.bankSwift')}</p>
                    <TextField
                        // label={t('profile.bankSwift')}
                        error={errorFormState.bank_swift ? true : false}
                        helperText={errorFormState.bank_swift}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        inputProps={{ name: 'bank_swift' }}
                        onChange={handleInfoChange}
                        value={profileFormState.bank_swift ?? ""}
                    />
                </div>

                <div className='w-full p-t-50'>
                    <div className='btn-theme w-full translateY' onClick={updateInfo}>
                        <p className='fs-button'>{t('button.update')}</p>
                    </div>
                </div>
            </Container>
            <Snackbar
                open={state.snackbarShow}
                autoHideDuration={2000}
                onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                    <CardContent style={{ padding: 10 }}>
                        <Typography style={{ color: 'white' }}>{state.message}</Typography>
                    </CardContent>
                </Card>
            </Snackbar>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));
import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#18267C',
        },
        secondary: {
            main: '#18267C',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f7f7f7',
            review: '#efefef',
            body: '#f6f7fa'
        },
        white: {
            main: '#FFFFFF',
        },
        green: {
            main: '#4caf50',
        },
        text: {
            primary: '#696d84',
            primaryLight: '#9ea1b7',
            lightBlue: '#fff',
        },
        gray: {
            main: '#b5b5b5',
            border: '#dee2e6',
            chip: '#b8b9b9',
            text: '#96A4C0',
            dtext: '#696969',
            textTitle: '#979797',
            ltext: '#d1d1d1',
            lightTitle: '#adacac',
        },
        silver: {
            bg: '#bebfc4',
            bgDarker: '#9d9ea2',
            text: '#939393',
            textDarker: '#6b6b6b',
        },
        price: {
            main: '#e53935',
        },
        button: {
            step: '#fdb93f',
        },
    },
    overrides: {
        
        MuiFilledInput: {
            root: {
                borderRadius: 48,
                background: '#88888817',
                backgroundColor: '#81818117',
                borderTopLeftRadius: 48,
                borderTopRightRadius: 48,
                boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.93), 2px 2px 4px 0px rgba(0, 0, 0, 0.09) inset',

            },
            adornedStart:{
                paddingLeft:24,
                paddingRight:24
            },
            multiline: {
                borderRadius: 10,
                padding:'27px 27px 10px',
            },
            input: {
                padding:'27px 27px 10px',
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #e3e3e3 inset',
                    borderTopLeftRadius: 48,
                    borderTopRightRadius: 48,
                    borderRadius: 48,
                    WebkitTextFillColor: '#222',
                    padding:'27px 27px 10px',
                },
            },
            inputMarginDense:{
                paddingTop:15,
                paddingBottom:15,
                padding:'15px 20px'
            },
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not($disabled):after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: 'none'
                },
                '&.Mui-disabled:before':{
                    borderBottom:'none'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 48,
                background: '#EFEFEF',
                backgroundColor: '#EFEFEF',
                boxShadow: 'inset 2px 2px 6px 0 #00000026',
            },
            multiline: {
                borderRadius: 10
            },
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #e3e3e3 inset',
                    borderTopLeftRadius: 48,
                    borderTopRightRadius: 48,
                    borderRadius: 48,
                    WebkitTextFillColor: '#222',
                },
            },
            notchedOutline: {
                border: 'none'
            }
        },
        PrivateTabIndicator: {
            colorSecondary: {
                backgroundColor: '#fff'
            }
        },
        MuiBackdrop: {
            root: {
                width: 420,
                margin: '0 auto',
                maxWidth: '100%'
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                width: 420,
                margin: '0 auto',
                maxWidth: '100%',
                maxHeight:'80%'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not($disabled):after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: 'none'
                }

            }
        },
        MuiInputLabel: {
            formControl:{
                left:16
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: '#F4F4F4'
            }
        },
        MuiButton:{
            contained:{
                 marginTop: '10px',
                 padding: '8px 21px',
                 backgroundColor: '#18267c',
                 color: '#fff',
                 borderRadius: '48px',
                 display: 'flex',
                 alignItems: 'center',
                 boxShadow: 'rgb(69 83 164 / 28%) 6px 6px 10px 0px'
            }
        }
    },
    breakpoints: {
        values: {
            ss: 300,
            s: 400,
            xs: 500,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
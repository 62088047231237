import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { CloseOutlined } from '@material-ui/icons';
import { Edit } from 'react-feather';

export default function CheckoutBuyerCard(props) {
    const { buyerInfo, setBuyerInfo, downlineInfo, setDownlineInfo, cartErrors } = props;
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    const [userDialog, setUserDialog] = useState({
        type: '',
        open: false,
        name: '',
        email: '',
        mobile: '',
        mobile_country_code: '',
    });

    const openUserDialog = (type, info) => {
        setUserDialog({
            type: type,
            open: true,
            name: info.name,
            email: info.email,
            mobile_country_code: info.mobile_country_code,
            mobile: info.mobile,
        });
    }
    const closeUserModal = () => {
        setUserDialog({ ...userDialog, open: false });
    }
    const saveUserModal = () => {
        if (userDialog.type === 'buyer') {
            setBuyerInfo({
                name: userDialog.name,
                email: userDialog.email,
                mobile_country_code: userDialog.mobile_country_code,
                mobile: userDialog.mobile,
            })
        }
        setUserDialog({ ...userDialog, open: false });
    }

    return (
        <div>
            <div className='glass2 shadow-glass2 bor15 p-all-15' style={{ marginTop: -80 }}>
                <div>
                    <div className='flex-sb-m w-full'>
                        <p className='fs-18 cl-theme-dark'><b>{t('checkout.buyer')}</b></p>
                        <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog('buyer', buyerInfo)}>
                            <Edit size={18} style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </div>
                    <div>
                        <p className='fs-14 cl-theme-dark'>{t('profile.name')}: {buyerInfo.name}</p>
                        {_.size(cartErrors[`buyer_email`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_email`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        <p className='fs-14 cl-theme-dark'>{t('profile.mobile')}: {`${buyerInfo.mobile_country_code ? `${buyerInfo.mobile_country_code} - ` : ' '}  ${buyerInfo.mobile}`}</p>
                        {_.size(cartErrors[`buyer_mobile_code`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_mobile_code`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        {_.size(cartErrors[`buyer_mobile`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_mobile`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        <p className='fs-14 cl-theme-dark'>{t('profile.email')}: {buyerInfo.email}</p>
                        {_.size(cartErrors[`buyer_email`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_email`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div style={{ paddingTop: 25 }}>
                    <Typography variant="h6" style={{ fontSize: 16, color: theme.palette.gray.text }}>{t('checkout.recipientUsername')}</Typography>
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={downlineInfo.username}
                        margin="dense"
                        onChange={(event) => setDownlineInfo({ ...downlineInfo, username: event.target.value })}
                    />
                    {_.size(cartErrors[`recipient_username`]) ?
                        <div className='p-t-5'>
                            {
                                _.map(cartErrors[`recipient_username`], (errMsg, key) => {
                                    return (
                                        <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                    )
                                })
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <Dialog open={userDialog.open} fullWidth onClose={closeUserModal}>
                <DialogTitle>
                    <Typography variant="h6">{t('checkout.editBuyerInfo')}</Typography>
                    <IconButton aria-label="close" className={styles.closeButton} onClick={closeUserModal}>
                        <CloseOutlined style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.name')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value })}
                        type="text"
                        value={userDialog.name}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('address.mobileCode')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile_country_code: target.value })}
                        type="text"
                        value={userDialog.mobile_country_code}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.mobile')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value })}
                        type="text"
                        value={userDialog.mobile}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.email')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value })}
                        type="text"
                        value={userDialog.email}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button fullWidth autoFocus onClick={saveUserModal} color="primary" variant="contained">
                        {t('button.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    dialogActionRoot: {
        margin: 0,
        padding: theme.spacing(1),
        border:'none'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

import React from 'react';
import { Typography, Box } from '@material-ui/core';

export default function TabPanel(props) {
    const { children, value, index, style = { backgroundColor: '#FFFFFF', minHeight: 300 }, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
            style={ style }
        >
            {value === index && <div className='p-tb-24 p-lr-10' >{children}</div>}
        </Typography>
    );
}
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

//MUI
import { Box, Button, FormControl, List, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { getUrl } from '@helper/ApiAction';
import { currencyFormat, useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

//COMPONENTS or LAYOUTS
import TransactionDialog from '@components/TransactionDialog';
import TransactionItem from '@components/TransactionItem';
import WithdrawalDialog from '@components/WithdrawalDialog';
import WithdrawalItem from '@components/WithdrawalItem';
import TitleBar from '@layouts/TitleBar';

export default function Transaction() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isMountedRef = useRef(null);

    const query = useQuery();
    const listType = query.get("list");

    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [transactionType, setTransactionType] = useState(listType ? listType : 'all');
    const [changeSelect, setChangeSelect] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));

    // ----------- APIs -------------
    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        setTransactionList([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        if (_.size(walletInfo) > 0) {
            switch (transactionType) {
                case 'all':
                case 'transfer':
                case 'convert':
                    getUrl(`wallets/${walletInfo.id}/history?page=${page}&per_page=10`, { code: transactionType === 'all' ? '' : transactionType }).then(response => {
                        if (response.status && isMountedRef.current) {
                            const { last_page, data } = response.data;
                            setTotalPage(last_page);
                            setTransactionList(_.concat(transactionList, data));
                        }
                    }).catch(err => {
                        addAlert(JSON.stringify(err.message));
                    }).finally(() => {
                        setLoading(false);
                        setChangeSelect(false);
                    });
                    break;
                case 'withdraw':
                    getUrl(`withdrawal?page=${page}&per_page=10`).then(response => {
                        if (response.status && isMountedRef.current) {
                            const { last_page, data } = response.data;
                            setTotalPage(last_page);
                            setTransactionList(_.concat(transactionList, data));
                        }
                    }).catch(err => {
                        addAlert(JSON.stringify(err.message));
                    }).finally(() => {
                        setLoading(false);
                        setChangeSelect(false);
                    });
                    break;
            }

        }

        return () => isMountedRef.current = false;
    }, [walletInfo, page, transactionType]);

    const changeTransactionType = ({ target }) => {
        setChangeSelect(true);
        setTransactionList([]);
        setPage(1);
        setTransactionType(target.value);
        history.replace(`/transaction/${wallet}?type=${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    return (
        <div>
            <TitleBar /*currencyButton*/ back backgroundColor="bg-theme" spendButton />

            <div className='bg-theme p-b-200 header-radius'></div>

            <div className='p-lr-25 p-t-20' style={{ marginTop: -170 }}>
                <div className='glass3 p-t-30 p-lr-20 p-b-70' style={{ borderRadius: 21, width: 290, margin: '0 auto' }}>
                    <div className='flex-sb-m w-full'>
                        <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                            <p className='fs-12 txt-upper'>{t('transfer.transferableBalance')}</p>
                            <p className='fs-24'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                        </div>
                        <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
                            <p className='fs-30 txt-upper'><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
                        </div>
                    </div>
                </div>

                <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                    <Box>
                        <FormControl className={`${classes.formControl} gradient-blue bor15`}>
                            <Select
                                value={transactionType}
                                name="transactionType"
                                onChange={changeTransactionType}
                                label={t('wallet.type')}
                                classes={{ icon: classes.iconStyle, root: classes.iconStyle }}
                            >
                                <MenuItem value={'all'}>{t('title.transactionHistory')}</MenuItem>
                                {walletInfo?.allow_transfer && <MenuItem value={'transfer'}>{t('title.transferHistory')}</MenuItem>}
                                {walletInfo?.allow_convert && <MenuItem value={'convert'}>{t('title.convertHistory')}</MenuItem>}
                                {walletInfo?.allow_withdraw && <MenuItem value={'withdraw'}>{t('title.withdrawalHistory')}</MenuItem>}
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>
            </div>

            <Box padding={2}>
                <List className={classes.listRoot}>
                    {_.map(transactionList, transactionItem => (
                        transactionType === 'withdraw'
                            ?
                            <WithdrawalItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                            :
                            <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                    ))}
                </List>
                {page < totalPage ?
                    <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                    : <Typography style={{ textAlign: 'center' }}>{t('transaction.endOfList')}</Typography>
                }
            </Box>

            {
                transactionType === 'withdraw'
                ?
                <WithdrawalDialog dialogOpen={dialogOpen} closeDialog={closeDialog} dialogInfo={dialogInfo} />
                :
                <TransactionDialog dialogOpen={dialogOpen} closeDialog={closeDialog} dialogInfo={dialogInfo} />
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    iconStyle: {
        color: '#fff',
        borderColor: '#fff'
    },
    paperContainer: {
        width: '90%',
        margin: '-38px auto 0',
        padding: '10px 20px',
        borderRadius: 10,
        boxShadow: 'rgba(124, 151, 255, 0.3) 0px 8px 10px 0px, rgba(27, 143, 150, 0.15) 0px -4px 7px 0px inset, rgba(255, 255, 255, 0.45) 0px 2px 4px 0px inset',
        background: 'linear-gradient(102deg, #6AE0FF 0%, #625FE7 96.56%)',
    },
    listRoot: {
        width: '100%',
        padding: '0px 25px',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));
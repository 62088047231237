import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { getUrl, postUrl } from '@helper/ApiAction';
import useNotificationLoading from '../../helper/useNotificationLoading';
import { currencyFormat } from '@helper/Tools';
import { storeWalletBalance } from '../../actions';

//MUI
import { makeStyles, TextField, useTheme, Container, Drawer , MenuItem } from '@material-ui/core';

//ICON
import { TiInfoLarge } from "react-icons/ti";

//COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';

const DEFAULT_ERROR_FIELD = { amount: "", security_password: "" };
// const DEFAULT_ERROR_FIELD = { amount: "", password: "" };

export default function Withdrawal() {
    const { bank_account, bank_holder_name, bank_name, bank_swift} = useSelector(state => state.user);
    const [withdrawRules, setWithdrawRules] = useState({
        "min": null,
        "max": null,
        "charges": null,
        "charges_type": null,
        "charges_value": null,
        "bicpay_withdrawals": false
    });
    
    const [walletInfo, setWalletInfo] = useState([]);
    const [walletName, setWalletName] = useState("");
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", security_password: "", dateTime: "", id: "" , type: "local"});
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [withdrawalStatus, setWithdrawalStatus] = useState("insert"); // insert -> review -> result
    const [withdrawal, setWithdrawal] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        let walletNameArray = _.split(existWallet.name, '|');
        setWalletName(_.size(walletNameArray) > 1 && i18n.language ==="cn" ? walletNameArray[1] : walletNameArray[0]);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo)) {
            getUrl('withdrawal_rules').then(response => {
                if(response.data && isMountedRef.current) {
                    if(response.status) {
                        if(parseInt(response?.data['wallet_id']) !== walletInfo.id) {
                            history.push('/wallet-home');
                        } else {
                            setWithdrawRules(response.data);
                            setState({...state, type: response.data?.bicpay_withdrawals ? 'bicpay' : 'local'});
                            // setMaxWithdraw(response.data?.max);
                            // setMinWithdraw(response.data?.min);
                        }
                    } else {
                        history.push('/wallet-home');
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [addAlert, walletInfo])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const reviewWithdrawal = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "security_password"], field => {
        //  _.map(["amount", "password"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('withdrawal.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        });

        if(withdrawRules.min > 0) {
            if(parseFloat(state.amount) < parseFloat(withdrawRules.min)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountLesserThan', { amount: withdrawRules.min });
            }
        }
        if(withdrawRules.max > 0) {
            if(parseFloat(state.amount) > parseFloat(withdrawRules.max)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountGreaterThan', { amount: withdrawRules.max });
            }
        }

        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if(!error) {
            withdrawWallet(true);
            // setWithdrawalStatus('review')
        } else {
            setWithdrawalStatus('insert')
        }
    }

    const withdrawWallet = (validateOnly) => {
        const { amount, security_password , type } = state;
        // const { amount, password } = state;
        const withdrawalData = {
            amount,
            security_password,
            // password,
            type,
            validate_only: validateOnly,
        };

        postUrl(`withdrawal`, withdrawalData).then(response => {
            if(response.status) 
                {
                if(validateOnly)
                {
                    setWithdrawalStatus('review');
                }
                else
                {
                    setWithdrawal(response?.withdrawal);
                    setWithdrawalStatus('result');
                    setErrorField(DEFAULT_ERROR_FIELD);
                    refetchWallet();
                }
            } 
            else 
            {
                if(response.errors) 
                {
                    let newErrorField = DEFAULT_ERROR_FIELD;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) 
                {
                    addAlert(response.data);
                }
                setWithdrawalStatus('insert')
            }
        }).catch(error => {
            setWithdrawalStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }

    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if(response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const getChargesValue = () => {
        const { charges_type, charges_value } = withdrawRules;

        if(charges_type === 'percent') {
            return (parseFloat(state.amount) * charges_value) / 100;
        } else {
            return charges_value;
        }
    }

    // const toggleDrawer = (anchor, open) => (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }
    //     setState({ ...state, [anchor]: open });
    // };

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back /*currencyButton*/  spendButton />
            <div className={`bg-theme header-radius ${withdrawalStatus === 'insert' ? 'p-b-40' : 'p-b-300'}`}>
                {withdrawalStatus === 'insert' ?
                    <div className='glass3 p-all-20 flex-c-m' style={{ borderRadius: 21, width: 350, height: 150, maxWidth: '85%', margin: '0 auto' }}>
                        <div className='flex-sb-m w-full'>
                            <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                                <p className='fs-remark txt-upper'>{t('withdrawal.withdrawableBalance')}</p>
                                <p className='fs-digit'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                            </div>
                            <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
                                <p className='fs-30 txt-upper'><b>{_.size(walletInfo) > 0 ? walletName : '-'}</b></p>
                            </div>
                        </div>
                    </div>
                    :
                    <Container>
                        {withdrawalStatus === 'result' &&
                            <div>
                                <p className='txt-center fs-header clwhite p-t-30'>{t('general.successfully')}</p>
                                <div className='subtitle-box'>
                                    <p className='cl-label fs-subheader lh-11 txt-center'>{t('transfer.successfullyTransferSubtitle')}</p>
                                </div>
                            </div>
                        }
                        {withdrawalStatus === 'review' &&
                            <div>
                                <p className='txt-center fs-header clwhite p-t-30'>{t('withdrawal.confirmWithdraw')}</p>
                                <div className='subtitle-box'>
                                    <p className='cl-label fs-subheader lh-11 txt-center'>{t('withdrawal.reviewSubtitle')}</p>
                                </div>
                            </div>
                        }
                    </Container>
                }
                
            </div>

            <Container>
                {withdrawalStatus === 'insert' ?
                    <div>
                        <div className='w-full flex-sb-m p-t-25'>
                            <p className='cl-theme fs-header'>{t('title.walletWithdrawal', { wallet: walletInfo.wallet_name })}</p>
                            {/* <div className='btn-square25-theme translateY' onClick={toggleDrawer('bottom', true)}>
                                <TiInfoLarge />
                            </div> */}
                        </div>

                        <div className='p-t-5 fs-content'>
                            <p>{t('withdrawal.minWithdraw', { amount: currencyFormat(withdrawRules.min) })}</p>
                            <p>{t('withdrawal.maxWithdraw', { amount: currencyFormat(withdrawRules.max) })}</p>
                            <p>{`${t('withdrawal.chargesFee', { amount: currencyFormat(withdrawRules.charges_value) })} ${withdrawRules.charges_type === 'percent' ? "%" : walletName}`}</p>
                        </div>

                        <div className='p-tb-25'>
                            <TextField
                                type="number"
                                variant="filled"
                                value={state.amount}
                                name="amount"
                                onChange={handleChange}
                                classes={{ root: classes.textFieldRoot }}
                                InputProps={{ readOnly: withdrawalStatus === 'review' ? true : false }}
                                label={t('withdrawal.amount')}
                                fullWidth
                                error={_.size(errorField.amount) ? true : false}
                                helperText={errorField.amount}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            />

                            {withdrawalStatus === 'review' ?
                                <TextField
                                    type="text"
                                    disabled
                                    variant="filled"
                                    value={getChargesValue()}
                                    onChange={handleChange}
                                    classes={{ root: classes.textFieldRoot }}
                                    label={t('withdrawal.feeCharges')}
                                    fullWidth
                                />
                                : null}

                            {/* <TextField
                            type="password"
                            variant="filled"
                            value={state.password}
                            name="password"
                            onChange={handleChange}
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: withdrawalStatus === 'review' ? true : false,
                            }}
                            label={t('withdrawal.password')}
                            error={_.size(errorField.password) ? true : false}
                            helperText={errorField.password}
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        /> */}
    
                            {/* <TextField
                                select
                                label={t('withdrawal.type')}
                                name="type"
                                value={state.type}
                                onChange={handleChange}
                                variant="filled"
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                fullWidth
                                error={_.size(errorField.type) ? true : false}
                                helperText={errorField.type}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            >
                                {
                                    withdrawRules.bicpay_withdrawals &&
                                    <MenuItem key={0} value={'bicpay'}>
                                        {t(`withdrawal.bicpay`)}
                                    </MenuItem>
                                }
                                <MenuItem key={1} value={'local'}>
                                    {t(`withdrawal.local`)}
                                </MenuItem>
                            </TextField> */}

                            <TextField
                                type="password"
                                variant="filled"
                                value={state.security_password}
                                name="security_password"
                                onChange={handleChange}
                                classes={{
                                    root: classes.textFieldRoot,
                                }}
                                InputProps={{
                                    readOnly: withdrawalStatus === 'review' ? true : false,
                                }}
                                label={t('withdrawal.security_password')}
                                error={_.size(errorField.security_password) ? true : false}
                                helperText={errorField.security_password}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                            />
                        </div>
                        <div className='btn-theme w-full' onClick={reviewWithdrawal}>
                            <p className='fs-button'>{t('button.next')}</p>
                        </div>
                    </div>

                    :
                    <div className='bg-base bor10 p-tb-20' style={{ marginTop: -250, boxShadow: '0px 4px 6px 0px rgba(112, 143, 223, 0.25)' }}>
                        <div className='txt-center p-all-20'>
                            <p className='fs-content cl-label'>{t('withdrawal.withdrawalAmount')}</p>
                            <p className='fs-35 cl-theme'>
                                <b>{`${currencyFormat(state.amount)} ${_.size(walletInfo) ? walletName : ''}`}</b>
                            </p>
                        </div>
                        <div className='w-full flex-sb-m'>
                            <div className='bg-theme bor50' style={{ width: 30, height: 30, marginLeft: -15 }} />
                            <div className='w-full' style={{ backgroundImage: 'url(/images/general/receipt/dash-theme.png)', backgroundRepeat: 'repeat-x', height: 4, backgroundPosition:'center' }}></div>
                            <div className='bg-theme bor50' style={{ width: 30, height: 30, marginRight: -15 }} />
                        </div>
                        <div className='p-all-20'>
                            {withdrawalStatus === 'result' &&
                                <div className='fs-content p-b-25'>
                                    <p className='txt-upper fs-title'>{withdrawal?.status_display}</p>
                                    <p className='fs-content'>{withdrawal?.created_at}</p>
                                </div>
                            }

                            <div className='fs-content'>
                                <p className='txt-upper cl-label fs-remark p-b-5'>{t('profile.bankInfo')}</p>
                                <p className='txt-upper fs-title'>{bank_name}</p>
                                <div className='fs-content'>
                                    <p>{bank_holder_name}</p>
                                    <p>{bank_account}</p>
                                </div>
                            </div>

                            <div className='p-t-25'>
                                <p className='txt-upper cl-label fs-remark p-b-5'>{t('withdrawal.withdrawalBreakdown')}</p>
                                <div className='flex-sb-m w-full'>
                                    <p>{t('withdrawal.withdrawalAmount')}</p>
                                    <p>{state.amount} {walletInfo.wallet_name }</p>
                                </div>
                                <div className='flex-sb-m w-full'>
                                    <p>{t('withdrawal.feeCharges')} ({currencyFormat(withdrawRules.charges_value) } {withdrawRules.charges_type === 'percent' ? "%" : walletName})</p>
                                    <p>{currencyFormat(getChargesValue())}</p>
                                </div>
                             
                                <div className='flex-sb-m w-full fs-title'>
                                    <p>{t('withdrawal.totalAmountReceived')}</p>
                                    <p>{currencyFormat(parseFloat(state.amount) - parseFloat(getChargesValue()))}</p>
                                </div>
                            </div>

                            <div className='p-t-25'>
                                {withdrawalStatus === 'review' &&
                                    <div className='w-full flex-sb-m'>
                                        <div className='btn-outlined-theme' style={{ width: '40%' }} onClick={() => setWithdrawalStatus('insert')}>
                                            <p className='fs-button'>{t('button.edit')}</p>
                                        </div>
                                        <div className='btn-theme' style={{ width: '54%' }} onClick={() => withdrawWallet(false)}>
                                            <p className='fs-button'>{t('withdrawal.withdrawNow')}</p>
                                        </div>
                                    </div>}
                                {withdrawalStatus === 'result' &&
                                    <div className='btn-theme w-full' onClick={() => history.push(`/wallet/${wallet}`)}>
                                        <p className='fs-button'>{t('withdrawal.complete')}</p>
                                    </div>
                                }
                            </div>
                        </div>

                        
                    </div>
                }
                
            </Container>

            {/* <Drawer 
                anchor="bottom" 
                open={state.bottom} 
                onClose={toggleDrawer('bottom', false)}
                PaperProps={{
                    style: {
                        borderRadius: '30px 30px 0px 0px',
                        boxShadow: 'none',
                    },
                }}
            >
                <div
                    role="presentation"
                    onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 cl-label'>Withdrawer Details</p>
                    
                    <div className='p-t-5 fs-content p-b-25'>
                        <p>{t('withdrawal.minWithdraw', { amount: currencyFormat(withdrawRules.min) })}</p>
                        <p>{t('withdrawal.maxWithdraw', { amount: currencyFormat(withdrawRules.max) })}</p>
                        <p>{`${t('withdrawal.chargesFee', { amount: currencyFormat(withdrawRules.charges_value) })} ${withdrawRules.charges_type === 'percent' ? "%" : walletName}`}</p>
                    </div>
                </div>
            </Drawer> */}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "#fff",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: '#fff', 
        fontSize: 11, 
        letterSpacing: 1 
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}
import React from 'react';
import { Link as RouterLink} from 'react-router-dom';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

//MUI
import { Card, Link,} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const PromotionsCard = ({ promotion }) => {
    const styles = useStyles();
    const theme = useTheme();

    const { i18n } = useTranslation();

    const getTitle = () => {
        const lang = i18n.language;
        return promotion[`subject_${lang}`] || promotion.subject_en;
    }

    const getImage = () => {
        const lang = i18n.language;
        return lang === 'cn' ? '/images/banner/promo-cn.png' : '/images/banner/promo-en.png';
    };

    const formattedDate = promotion.updated_at ? moment(promotion.updated_at).format('YYYY-MM-DD') : '';

    return (
        <Link component={RouterLink} underline='none' to={`/notification-detail/${promotion.id}`}>
            <Card className={`${styles.promotionCard} pointer`}>
                <div className={styles.promotionImageContainer}>
                    <img src={getImage()} alt={getTitle()} className={styles.promotionImage} />
                </div>
            </Card>
            <p className='fs-content txt_truncate p-r-15'>{getTitle()}</p>
            <p className="fs-remark txt-text-dark">{formattedDate}</p>
        </Link>
    );
}

PromotionsCard.propTypes = {
    promotion: PropTypes.object.isRequired,
    // unreadCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
    promotionCard: {
        borderRadius: 15,
        overflow: 'hidden',
        marginBottom: theme.spacing(2),
        boxShadow: '3px 3px 6px 0 #0002',
        margin: theme.spacing(2),
        minWidth: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        marginLeft: 0,
        marginTop:0
    },
    promotionImageContainer: {
        position: 'relative',
        height: 140,
        backgroundColor: '#f5f5f5',
    },
    promotionImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export default PromotionsCard;